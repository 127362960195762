import { Stack, Button, background } from "@chakra-ui/react";
import { Icon, createIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import state from "../../../state";
import { useSnapshot } from "valtio";

const MotionButton = motion(Button);

export default function SettingsBar(props) {
  const { i18n } = useTranslation();
  // const backgroundMusic = new Audio(song);  
  // backgroundMusic.play();
  // backgroundMusic.loop = true;

  const {
    musicIsPlaying
  } = useSnapshot(state);

  const HomeIcon = (props) => (
    <Icon viewBox="0 0 48 48" {...props}>
      <path
        fill="currentColor"
        d="M39.5,43h-9c-1.381,0-2.5-1.119-2.5-2.5v-9c0-1.105-0.895-2-2-2h-4c-1.105,0-2,0.895-2,2v9c0,1.381-1.119,2.5-2.5,2.5h-9	C7.119,43,6,41.881,6,40.5V21.413c0-2.299,1.054-4.471,2.859-5.893L23.071,4.321c0.545-0.428,1.313-0.428,1.857,0L39.142,15.52	C40.947,16.942,42,19.113,42,21.411V40.5C42,41.881,40.881,43,39.5,43z"
      />
    </Icon>
  );

  const goToMainMenu = () => {
    state.createStep = 0;
    state.selectedStage = 0;
    state.detailStep = 0;
    state.landingStep = 0;
    state.camera.cameraPosition = (-12, 9, 10.5);
  };

  // const playPause = () => {
    
  //   console.log("musicIsPlaying："+musicIsPlaying);

  //   if (musicIsPlaying) {
  //     // Pause the song if it is playing
  //     console.log("pause")
  //     backgroundMusic.pause();
  //     state.musicIsPlaying = false;
  //   } else {
  //     // Play the song if it is paused
  //     console.log("play")
  //     backgroundMusic.play();
  //     backgroundMusic.loop = true;
  //     state.musicIsPlaying = true;
  //   }
  // };

  return (
    <Stack
      p="5"
      direction="row"
      justifyContent="space-between"
      alignItems={"flex-start"}
    >
      <Stack direction="row" justifyContent="flex-end" alignItems="flex-start">
        {/* Back to main menu button */}
        {props.showHomeButton ? (
          <MotionButton
            w="32px"
            h="32px"
            color="lightgrey"
            borderRadius="100%"
            boxShadow="xl"
            fontSize={"1.4rem"}
            p="5"
            animate={{ opacity: [0, 1] }}
            backgroundColor="white"
            transition={{
              duration: 0.2,
              type: "spring",
              ease: "easeOut",
            }}
            style={{ opacity: 1 }}
            _hover={{ backgroundColor: "white" }}
            _active={{ backgroundColor: "white" }}
            onClick={goToMainMenu}
          >
            <HomeIcon />
          </MotionButton>
        ) : null}
      </Stack>
      <Stack direction="row" justifyContent="flex-end" alignItems="flex-start">
        {/* Audio Toggle */}
        {/* <MotionButton
            w="32px"
            h="32px"
            color="lightgrey"
            borderRadius="100%"
            fontSize={"sm"}
            boxShadow="xl"
            backgroundColor="white"
            p="5"
            animate={{ opacity: [0, 1] }}
            transition={{
              duration: 0.2,
              type: "spring",
              ease: "easeOut",
            }}
            style={{ opacity: 0 }}
            _hover={{ backgroundColor: "white" }}
            _active={{ backgroundColor: "white" }}
            onClick={playPause}
          >
            {musicIsPlaying?"0":"1"}
        </MotionButton> */}
        {/* Loacale switcher */}
        <MotionButton
          w="32px"
          h="32px"
          color="lightgrey"
          borderRadius="100%"
          fontSize={"sm"}
          boxShadow="xl"
          backgroundColor="white"
          p="5"
          animate={{ opacity: [0, 1] }}
          transition={{
            duration: 0.2,
            type: "spring",
            ease: "easeOut",
          }}
          style={{ opacity: 0 }}
          _hover={{ backgroundColor: "white" }}
          _active={{ backgroundColor: "white" }}
          onClick={() =>
            i18n.changeLanguage(i18n.language === "zh" ? "en" : "zh")
          }
        >
          {i18n.language === "zh" ? "EN" : "中"}
        </MotionButton>
      </Stack>
    </Stack>
  );
}

import * as THREE from "three";
import { Euler } from "three";

const generateLandingCranes = (number) =>
  new Array(number).fill().map((value, index) => {
    const scaleRand = THREE.MathUtils.randFloat(0.5, 2);
    return {
      position: [
        THREE.MathUtils.randFloat(50, 125),
        THREE.MathUtils.randFloat(5, 60),
        THREE.MathUtils.randFloat(-40, 40),
      ],
      scale: [scaleRand, scaleRand, scaleRand],
      rotation: new Euler(
        THREE.MathUtils.randFloat(0, 0),
        THREE.MathUtils.randFloat(-1.0, -2.2),
        THREE.MathUtils.randFloat(-0.1, -0.2)
      ),
    };
  });

export default generateLandingCranes;

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { DoubleSide } from "three";

export default function Groud({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/assets/models/ground.gltf");

  return (
    <group ref={group} {...props} dispose={null} receiveShadow>
      <mesh geometry={nodes.Icosphere005.geometry}>
        <meshStandardMaterial
          side={DoubleSide}
          emissive={0x15eddf}
          color={0x15eddf}
          emissiveIntensity={0.1}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("/assets/models/ground.gltf");

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { DoubleSide } from "three";

export default function Paper({ ...props }) {
  const { nodes, materials } = useGLTF("assets/models/paper2.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Object003.geometry}
        position={[0, 0, 0]}
        rotation={[0.4, 0, 0.03]}
        scale={1}
      >
        <meshStandardMaterial map={props.map} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/assets/models/paper2.gltf");

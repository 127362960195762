import { Box, useRadio } from "@chakra-ui/react";

export default function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="2px"
        borderRadius="xl"
        borderColor="white"
        boxShadow="2xl"
        textAlign="center"
        fontWeight="semibold"
        whiteSpace="pre-line"
        color="white"
        backgroundColor="rgb(255 255 255 / 15%)"
        _checked={{
          bg: "white",
          color: "gray",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
}

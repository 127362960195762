import { OverlayUIContainerWithBackdrop } from "../../../../components/OverlayUIContainer";
import { Flex, Text } from "@chakra-ui/layout";
import { NumberInput, NumberInputField, Button, Icon } from "@chakra-ui/react";
import { HiArrowRight } from "react-icons/hi";

import { useTranslation } from "react-i18next";

import SettingsBar from "../../commons/SettingsBar";
import { useState } from "react";
import state from "../../../../state";
import { Api } from "../../../../api";

export default function SearchStageUI() {
  const [craneNumber, setCraneNumber] = useState(0);
  const { t } = useTranslation();

  const onClickSearch = async () => {
    const crane = await Api.getCraneById(craneNumber);
    state.cachedCrane = crane;
    state.selectedStage = 4;
  };

  return (
    <OverlayUIContainerWithBackdrop>
      <Flex
        flex={1}
        direction={"column"}
        justifyContent="space-between"
        alignContent="center"
        pointerEvents="auto"
      >
        <SettingsBar showHomeButton />
        <Flex
          direction={"column"}
          justifyContent={"center"}
          flex={1}
          alignItems={"center"}
          p={"4"}
        >
          <Text fontSize={"3xl"} color={"white"}>
            {t("search_crane.search_title")}
          </Text>
          <Flex
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            marginTop={"6"}
            marginBottom={"32"}
            width={"100%"}
          >
            <NumberInput>
              <NumberInputField
                value={craneNumber}
                color={"white"}
                onChange={(event) => setCraneNumber(event.target.value)}
                placeholder={t("search_crane.search_input_placeholder")}
                _placeholder={{ color: "whiteAlpha.800" }}
              />
            </NumberInput>
            <Button marginLeft={"2"} onClick={onClickSearch}>
              <Icon as={HiArrowRight} color={"gray"} w={5} h={5} />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </OverlayUIContainerWithBackdrop>
  );
}

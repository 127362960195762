import React, { useEffect, useMemo, useRef, useState } from "react";
import { useGLTF, useAnimations, Html } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useTranslation } from "react-i18next";
import * as THREE from "three";

export default function OrigamiCrane({ ...props }) {
  const group = useRef();
  const meshRef = useRef();
  const mainGroup = useRef();
  const matRef = useRef();

  const { t } = useTranslation();

  const { nodes, materials, animations } = useGLTF(
    "/assets/models/test_crane.gltf"
  );
  const { actions } = useAnimations(animations, mainGroup);

  const [nearbyCamera, setNearByCamera] = useState(false);

  const targetMap = useMemo(
    () => new THREE.TextureLoader().load(props.userData.textureUrl),
    [props.userData]
  );

  useEffect(() => {
    // Play default animation
    actions.KeyAction.timeScale = props.userData.animationSpeed;
    actions.ArmatureAction.timeScale = props.userData.animationSpeed;
    actions.KeyAction.play();
  }, []);

  useFrame((state, delta) => {
    // rotate self animation with z-axis
    group.current.rotation.y += props.userData.animationSpeed * delta;

    // check camera distance for html overlay UI
    let crane2DPosition = group.current.position.clone();
    let camera2DPosition = state.camera.position.clone();
    // crane2DPosition.y = 0;
    // camera2DPosition.y = 0;
    const distaneBetweenCameraAndCrane =
      camera2DPosition.distanceTo(crane2DPosition);
    setNearByCamera(
      distaneBetweenCameraAndCrane >= 15 && distaneBetweenCameraAndCrane <= 180
    );
  });

  const onDebugButtonClick = () => {
    if (props.onClickDetail) props.onClickDetail(group.current.userData);
  };

  return (
    <group ref={group} {...props} dispose={null}>
      <group ref={mainGroup}>
        <group>
          <group>
            <group rotation={[0.09, Math.PI / 2, 0]}>
              <group position={[0, 0.05, 0]} rotation={[-0.01, -0.04, 2.86]}>
                <group position={[0, 0.36, 0]} rotation={[0.03, -0.07, -2.36]}>
                  <group
                    position={[0, 0.69, 0]}
                    rotation={[-0.02, -0.05, 1.71]}
                  />
                </group>
              </group>
              <group position={[0, 0.05, 0]} rotation={[-0.01, 0.04, -2.86]}>
                <group position={[0, 0.36, 0]} rotation={[0.03, 0.07, 2.38]} />
              </group>
              <group position={[0, 0.05, 0]} rotation={[3.04, 0, 0]}>
                <group position={[0, 0.35, 0]} rotation={[-2.97, 0, 0]}>
                  <group position={[0, 0.24, 0]} rotation={[0.93, 0, 0]}>
                    <group position={[0, 0.17, 0]} rotation={[-0.13, 0, 0]}>
                      <group position={[0, 0.18, 0]} rotation={[0.01, 0, 0]}>
                        <group position={[0, 0.2, 0]} rotation={[0.17, 0, 0]} />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0, 0.05, 0]} rotation={[-3.04, 0, 0]}>
                <group position={[0, 0.35, 0]} rotation={[2.97, 0, 0]}>
                  <group position={[0, 0.24, 0]} rotation={[-0.93, 0, 0]}>
                    <group position={[0, 0.17, 0]} rotation={[0.13, 0, 0]}>
                      <group position={[0, 0.18, 0]} rotation={[-0.01, 0, 0]}>
                        <group
                          name="Bone017"
                          position={[0, 0.2, 0]}
                          rotation={[-0.17, 0, 0]}
                        />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
          <mesh
            ref={meshRef}
            name="crane_mesh001"
            geometry={nodes.crane_mesh001.geometry}
            material={materials["Material.002"]}
            morphTargetDictionary={nodes.crane_mesh001.morphTargetDictionary}
            morphTargetInfluences={nodes.crane_mesh001.morphTargetInfluences}
          >
            <meshStandardMaterial
              ref={matRef}
              emissive={0xebebeb}
              emissiveIntensity={0}
              color={0xebebeb}
              map={targetMap}
            />

            <Html
              as="div"
              distanceFactor={10}
              position={[0, 0, 0]}
              sprite
              transform
              pointerEvents={null}
              style={{
                display: nearbyCamera ? "block" : "none",
                userSelect: "none",
                marginRight: "-400px",
                width: "330px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* Annotation Line */}
                <div
                  style={{
                    content: " ",
                    display: "block",
                    top: "50%",
                    width: "120px",
                    height: "1px",
                    backgroundColor: "white",
                  }}
                />

                {/* Detail Panel */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "transparent",
                    color: "white",
                  }}
                >
                  <div
                    style={{
                      fontSize: "1.8rem",
                      textShadow: "1.5px 1.5px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {t("explore_crane.crane_with_unit", {
                      id: props.userData.id,
                    })}
                  </div>
                  <div
                    style={{
                      fontSize: "1.2rem",
                      textShadow: "0.5px 0.5px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {t("wishes." + props.userData.wish)}
                  </div>
                  <button
                    style={{
                      pointerEvents: "auto",
                      background: "white",
                      color: "black",
                      borderRadius: "4px",
                      border: "1px solid white",
                      padding: "0.3rem",
                      fontSize: "0.5rem",
                      marginTop: "0.5rem",
                    }}
                    type="button"
                    onClick={onDebugButtonClick}
                  >
                    {t("action.view_detail")}
                  </button>
                </div>
              </div>
            </Html>
          </mesh>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/test_crane.gltf");

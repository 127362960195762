import { Canvas } from "@react-three/fiber";
import AudioVisualiserBall from "../../../../../components/ThreeDModel/AudioVisualiserBall";

export default function AudioVisualiser(props) {
  return (
    <Canvas
      style={{
        width: "100vw",
        height: "100vw",
        display: "block",
        alignSelf: "center",
        zIndex:"-500",
      }}
      flat
      dpr={[1, 2]}
      camera={{
        fov: 100,
        position: [0, 0, -12],
        near: 0.1,
        far: 500,
      }}
    >
      {/* <color attach="background" args={["black"]} /> */}
      <ambientLight />
      <AudioVisualiserBall position={[0, 5, 20]} scale={[7.5, 7.5, 7.5]} />
    </Canvas>
  );
}

// React core
import { Suspense, useEffect } from "react";

// ThreeJS and Models
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import {
  OrbitControls,
  PerspectiveCamera,
  Sphere,
  useTexture,
  Cone,
} from "@react-three/drei";
import { SpecialOrigamiCrane } from "../../../../components/ThreeDModel/SpecialOrigamiCrane";
import { Vector3 } from "three";

// State management
import state from "../../../../state";

export default function SpecialStage() {
  const { gl, scene, camera, size } = useThree();
  const step = 0.01;
  const specialTexture1 = new THREE.TextureLoader().load( "/assets/textures/special/1.png" );
  const specialTexture2 = new THREE.TextureLoader().load( "/assets/textures/special/2.png" );
  const specialTexture3 = new THREE.TextureLoader().load( "/assets/textures/special/3.png" );
  const specialTexture4 = new THREE.TextureLoader().load( "/assets/textures/special/4.png" );
  const specialTexture5 = new THREE.TextureLoader().load( "/assets/textures/special/5.png" );
  const specialTexture6 = new THREE.TextureLoader().load( "/assets/textures/special/6.png" );
  const specialTexture7 = new THREE.TextureLoader().load( "/assets/textures/special/7.png" );
  const specialTexture8 = new THREE.TextureLoader().load( "/assets/textures/special/8.png" );
  const specialTexture9 = new THREE.TextureLoader().load( "/assets/textures/special/9.png" );
  const specialTexture10 = new THREE.TextureLoader().load( "/assets/textures/special/10.png" );
  const specialTexture11 = new THREE.TextureLoader().load( "/assets/textures/special/11.png" );
  const specialTexture12 = new THREE.TextureLoader().load( "/assets/textures/special/12.png" );

  const initStage = async () => {
  };

  useEffect(() => {
    initStage();
    return () => {
      state.allCranes = [];
    };
  }, []);

  const onClickCraneDetail = (craneData) => {
    state.selectedStage = 4;
  };

  return (
    <>
      <Suspense fallback={null}>
        //1st row
        <SpecialOrigamiCrane
              playAnimation
              key={"landing_crane1"}
              hideHtml
              position={[18, 2, 0]}
              scale={[10.8, 10.5, 10.35]}
              rotation={[0, 1.9, -0.2]}
              map={specialTexture1}
              info={{
                title_en:"Scenic Rockery",
                desc_en:"Napp Studio & Architects",
                url_en:"https://scienceinart.hk/en/work/napp/",
                title_zh:"山水風光",
                desc_zh:"納設計及建築事務所",
                url_zh:"https://scienceinart.hk/work/napp/"
              }}
            />
        <SpecialOrigamiCrane
              playAnimation
              key={"landing_crane2"}
              hideHtml
              position={[0, 0, -8]}
              scale={[5.8, 5.5, 5.35]}
              rotation={[0, 3.9, -0.2]}
              map={specialTexture2}
              info={{
                title_en:"The Ornithopter – Spirit of Hong Kong",
                desc_en:"HIR Studio",
                url_en:"https://scienceinart.hk/en/work/hir/",
                title_zh:"夢想實驗號",
                desc_zh:"HIR建築設計室",
                url_zh:"https://scienceinart.hk/work/hir/"
              }}
            />
        <SpecialOrigamiCrane
              playAnimation
              key={"landing_crane3"}
              hideHtml
              position={[-18, -2, 0]}
              scale={[11.8, 11.5, 11.35]}
              rotation={[0, -2.9, -0.2]}
              map={specialTexture3}
              info={{
                title_en:"The Interplay",
                desc_en:"Whiteground",
                url_en:"https://scienceinart.hk/en/work/whiteground/",
                title_zh:"光織",
                desc_zh:"白本",
                url_zh:"https://scienceinart.hk/work/whiteground/"
              }}
            />
        //2nd row
        <SpecialOrigamiCrane
              playAnimation
              key={"landing_crane4"}
              hideHtml
              position={[23, -1.5, 15]}
              scale={[5.8, 5.5, 5.35]}
              rotation={[0, -4.9, -0.2]}
              map={specialTexture4}
              info={{
                title_en:"Obscure Bond",
                desc_en:"Chun-kit Cheuk",
                url_en:"https://scienceinart.hk/en/work/kit-ado/",
                title_zh:"不可見的連繫",
                desc_zh:"卓震傑",
                url_zh:"https://scienceinart.hk/work/kit-ado/"
              }}
            />
        <SpecialOrigamiCrane
              playAnimation
              key={"landing_crane5"}
              hideHtml
              position={[-25, 0, 15]}
              scale={[5.8, 5.5, 5.35]}
              rotation={[0, -1.9, -0.2]}
              map={specialTexture5}
              info={{
                title_en:"In the Wind",
                desc_en:"Studio RYTE",
                url_en:"https://scienceinart.hk/en/work/ryte/",
                title_zh:"風度",
                desc_zh:"衡建設計",
                url_zh:"https://scienceinart.hk/work/ryte/"
              }}
            />
        //3rd row
        <SpecialOrigamiCrane
              playAnimation
              key={"landing_crane6"}
              hideHtml
              position={[30, -2, 30]}
              scale={[14.8, 14.5, 14.35]}
              rotation={[0, -5.2, -0.2]}
              map={specialTexture6}
              info={{
                title_en:"Water Capsule Submarine",
                desc_en:"New Office Works",
                url_en:"https://scienceinart.hk/en/work/now/",
                title_zh:"水滴潛艇",
                desc_zh:"New Office Works",
                url_zh:"https://scienceinart.hk/work/now/"
              }}
            />
        <SpecialOrigamiCrane
              playAnimation
              key={"landing_crane7"}
              hideHtml
              position={[-32, 1, 30]}
              scale={[5.8, 5.5, 5.35]}
              rotation={[0, -1.2, -0.3]}
              map={specialTexture7}
              info={{
                title_en:"Eternal Light of a Seashell",
                desc_en:"Victor Wong",
                url_en:"https://scienceinart.hk/en/work/victor-wong-spu-eternal-light-of-a-seashell/",
                title_zh:"永恆光海貝",
                desc_zh:"黃宏達",
                url_zh:"https://scienceinart.hk/work/victor-wong-spu-eternal-light-of-a-seashell/"
              }}
            />
         //4th row
        <SpecialOrigamiCrane
              playAnimation
              key={"landing_crane8"}
              hideHtml
              position={[24, 1.6, 45]}
              scale={[5.8, 5.5, 5.35]}
              rotation={[0, -5.7, -0.2]}
              map={specialTexture8}
              info={{
                title_en:"Loving Home",
                desc_en:"Victor Wong",
                url_en:"https://scienceinart.hk/en/work/victor-wong-spu-loving-home/",
                title_zh:"愛回家",
                desc_zh:"黃宏達",
                url_zh:"https://scienceinart.hk/work/victor-wong-spu-loving-home/"
              }}
            />
        <SpecialOrigamiCrane
              playAnimation
              key={"landing_crane9"}
              hideHtml
              position={[-28, 2.2, 45]}
              scale={[11.8, 11.5, 11.35]}
              rotation={[0, -1.9, -0.2]}
              map={specialTexture9}
              info={{
                title_en:"Coral Fractals",
                desc_en:"Victor Wong",
                url_en:"https://scienceinart.hk/en/work/victor-wong-spu-coral-fractals/",
                title_zh:"珊瑚無極",
                desc_zh:"黃宏達",
                url_zh:"https://scienceinart.hk/work/victor-wong-spu-coral-fractals/"
              }}
            />
        //5th row
        <SpecialOrigamiCrane
              playAnimation
              key={"landing_crane10"}
              hideHtml
              position={[18, 1, 60]}
              scale={[5.8, 5.5, 5.35]}
              rotation={[0, -5.9, -0.2]}
              map={specialTexture10}
              info={{
                title_en:"The Future Metropolis",
                desc_en:"KK Leung",
                url_en:"https://scienceinart.hk/en/work/kk-leung/",
                title_zh:"未來都會",
                desc_zh:"梁建強",
                url_zh:"https://scienceinart.hk/work/kk-leung/"
              }}
            />
        <SpecialOrigamiCrane
              playAnimation
              key={"landing_crane11"}
              hideHtml
              position={[0, -3, 65]}
              scale={[7.8, 7.5, 7.35]}
              rotation={[0, -0.9, -0.2]}
              map={specialTexture11}
              info={{
                title_en:"Time Traveller",
                desc_en:"Brenda Hui",
                url_en:"https://scienceinart.hk/en/work/brenda-hui/",
                title_zh:"時空旅客",
                desc_zh:"許智賢",
                url_zh:"https://scienceinart.hk/work/brenda-hui/"
              }}
            />
        <SpecialOrigamiCrane
              playAnimation
              key={"landing_crane12"}
              hideHtml
              position={[-18, 1, 60]}
              scale={[5.8, 5.5, 5.35]}
              rotation={[0, -0.3, -0.2]}
              map={specialTexture12}
              info={{
                title_en:"Here in the Past",
                desc_en:"Oldgrand.ma",
                url_en:"https://scienceinart.hk/en/work/oldgrand-ma/",
                title_zh:"原地旅行",
                desc_zh:"Oldgrand.ma",
                url_zh:"https://scienceinart.hk/work/oldgrand-ma/"
              }}
            />
        
        <Sphere position={[130.2, -50, -120]} scale={[100, 100, 30]}>
          <meshBasicMaterial color="lightblue" wireframe={true} />
        </Sphere>
        <Sphere position={[-10.2, 20, -50]} scale={[5, 5, 5]}>
          <meshBasicMaterial color="lightblue" wireframe={true} />
        </Sphere>
        <Sphere position={[-50.2, 40, 30]} scale={[15, 15, 15]}>
          <meshBasicMaterial color="white" wireframe={true} />
        </Sphere>
        <Cone
          position={[-100, 0, -150]}
          scale={[60, 60, 60]}
          rotation={[0, 180, 180]}
        >
        <meshBasicMaterial color="hotpink" wireframe={true} />
        </Cone>
        <Cone
          position={[-70.2, -50, 30]}
          scale={[30, 30, 30]}
          rotation={[0, 0, 180]}
        >
        <meshBasicMaterial color="lightblue" wireframe={true} />
        </Cone>
        <Sphere position={[140, -0, 30]} scale={[60, 26, 46]}>
          <meshBasicMaterial color="hotpink" wireframe={true} />
        </Sphere>
      </Suspense>

      <PerspectiveCamera makeDefault position={[50, 50, 30]} fov={[50]} />
      <OrbitControls
        makeDefault
        target={[0, 0, 30]}
        autoRotate={true}
        autoRotateSpeed={1}
        minPolarAngle={1.3}
        maxPolarAngle={2.1}
      />
    </>
  );
}

import React, { useRef, useEffect, useMemo } from "react";
import { useGLTF, useTexture, Html, useAnimations } from "@react-three/drei";
import { useSnapshot } from "valtio";
import state from "../../state";
import * as THREE from "three";

export function StaticOrigamiCrane({ ...props }) {
  const group = useRef();

  const { generatedTextureUrl } = useSnapshot(state);

  const { nodes, materials, animations } = useGLTF(
    "/assets/models/test_crane.gltf"
  );
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    if (props.playAnimation) {
      // Play default animation
      const randSpeed = Math.random() * 0 - 0.1;
      actions.KeyAction.timeScale = randSpeed;
      actions.ArmatureAction.timeScale = randSpeed;
      actions.KeyAction.play();
    }
  }, []);

  const renderMainMenu = () => {
    return props.hideHtml ? null : (
      <Html
        as="div"
        distanceFactor={1}
        position={[0, 0, 0]}
        sprite
        pointerEvents={null}
        style={{
          userSelect: "none",
        }}
      >
        <div
          style={{
            content: " ",
            backgroundColor: "transparent",
            borderRadius: "100%",
            width: "2rem",
            height: "2rem",
            border: "1px solid white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              content: " ",
              backgroundColor: "white",
              borderRadius: "100%",
              width: "1rem",
              height: "1rem",
            }}
          ></div>
        </div>
        <div
          style={{
            position: "absolute",
            paddingTop: "2rem",
            background: "white",
            width: "5rem",
            left: "-1.5rem",
            textAlign: "center",
            color: "black",
            pointerEvents: "auto",
          }}
        >
          <button
            style={{
              fontSize: "2rem",
              textShadow: "0.1rem 0.1rem rgba(0, 0, 0, 0.5)",
            }}
            onClick={props.onClick}
          >
            {props.title}
          </button>
        </div>
      </Html>
    );
  };

  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group>
          <group>
            <group rotation={[0.09, Math.PI / 2, 0]}>
              <group position={[0, 0.05, 0]} rotation={[-0.01, -0.04, 2.86]}>
                <group position={[0, 0.36, 0]} rotation={[0.03, -0.07, -2.36]}>
                  <group
                    position={[0, 0.69, 0]}
                    rotation={[-0.02, -0.05, 1.71]}
                  />
                </group>
              </group>
              <group position={[0, 0.05, 0]} rotation={[-0.01, 0.04, -2.86]}>
                <group position={[0, 0.36, 0]} rotation={[0.03, 0.07, 2.38]} />
              </group>
              <group position={[0, 0.05, 0]} rotation={[3.04, 0, 0]}>
                <group position={[0, 0.35, 0]} rotation={[-2.97, 0, 0]}>
                  <group position={[0, 0.24, 0]} rotation={[0.93, 0, 0]}>
                    <group position={[0, 0.17, 0]} rotation={[-0.13, 0, 0]}>
                      <group position={[0, 0.18, 0]} rotation={[0.01, 0, 0]}>
                        <group position={[0, 0.2, 0]} rotation={[0.17, 0, 0]} />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0, 0.05, 0]} rotation={[-3.04, 0, 0]}>
                <group position={[0, 0.35, 0]} rotation={[2.97, 0, 0]}>
                  <group position={[0, 0.24, 0]} rotation={[-0.93, 0, 0]}>
                    <group position={[0, 0.17, 0]} rotation={[0.13, 0, 0]}>
                      <group position={[0, 0.18, 0]} rotation={[-0.01, 0, 0]}>
                        <group
                          name="Bone017"
                          position={[0, 0.2, 0]}
                          rotation={[-0.17, 0, 0]}
                        />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
          <mesh
            name="crane_mesh001"
            geometry={nodes.crane_mesh001.geometry}
            // material={materials["Material.002"]}
            morphTargetDictionary={nodes.crane_mesh001.morphTargetDictionary}
            morphTargetInfluences={nodes.crane_mesh001.morphTargetInfluences}
            onClick={props.onClick}
          >
            {props.map ? (
              <meshStandardMaterial
                emissive={0xebebeb}
                emissiveIntensity={0}
                color={0xebebeb}
                map={props.map}
              />
            ) : (
              <meshStandardMaterial
                emissive={0xebebeb}
                emissiveIntensity={0}
                color={0xebebeb}
              />
            )}

            {renderMainMenu()}
          </mesh>
        </group>
      </group>
    </group>
  );
}

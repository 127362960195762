import { BrowserRouter, Routes, Route } from "react-router-dom";
import ThreeDWorld from "./pages/threejs";
import styled from "@emotion/styled";

import "./App.css";
import { Suspense } from "react";

import "./firebase.js";
import song from "./bird.mp3";

const AppContainer = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;
// const backgroundMusic = new Audio(song);  
// backgroundMusic.play();
// backgroundMusic.loop = true;

function App() {
  return (
    <BrowserRouter>
      <AppContainer>
        <Routes>
          <Route path="/" element={<ThreeDWorld />} />
          {/* Other page goes below */}
          {/* <Route path="/otherPage" element={<OtherPage />} /> */}
        </Routes>
      </AppContainer>
    </BrowserRouter>
  );
}

export default App;

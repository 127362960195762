import React, { useRef, useMemo, useEffect } from "react";
import * as THREE from "three";
import { MathUtils } from "three";
import { useFrame, useThree } from "@react-three/fiber";
import state from "../../state";
import AudioMotionAnalyzer from "audiomotion-analyzer";
import { v4 as uuidv4 } from "uuid";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useSnapshot } from "valtio";

const vertexShader = `
    vec3 mod289(vec3 x)
    {
        return x - floor(x * (1.0 / 289.0)) * 289.0;
    }
    
    vec4 mod289(vec4 x)
    {
        return x - floor(x * (1.0 / 289.0)) * 289.0;
    }
    
    vec4 permute(vec4 x)
    {
        return mod289(((x*34.0)+1.0)*x);
    }
    
    vec4 taylorInvSqrt(vec4 r)
    {
        return 1.79284291400159 - 0.85373472095314 * r;
    }
    
    vec3 fade(vec3 t) {
        return t*t*t*(t*(t*6.0-15.0)+10.0);
    }
    
    // Classic Perlin noise
    float cnoise(vec3 P)
    {
        vec3 Pi0 = floor(P); // Integer part for indexing
        vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
        Pi0 = mod289(Pi0);
        Pi1 = mod289(Pi1);
        vec3 Pf0 = fract(P); // Fractional part for interpolation
        vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
        vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
        vec4 iy = vec4(Pi0.yy, Pi1.yy);
        vec4 iz0 = Pi0.zzzz;
        vec4 iz1 = Pi1.zzzz;
    
        vec4 ixy = permute(permute(ix) + iy);
        vec4 ixy0 = permute(ixy + iz0);
        vec4 ixy1 = permute(ixy + iz1);
    
        vec4 gx0 = ixy0 * (1.0 / 7.0);
        vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
        gx0 = fract(gx0);
        vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
        vec4 sz0 = step(gz0, vec4(0.0));
        gx0 -= sz0 * (step(0.0, gx0) - 0.5);
        gy0 -= sz0 * (step(0.0, gy0) - 0.5);
    
        vec4 gx1 = ixy1 * (1.0 / 7.0);
        vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
        gx1 = fract(gx1);
        vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
        vec4 sz1 = step(gz1, vec4(0.0));
        gx1 -= sz1 * (step(0.0, gx1) - 0.5);
        gy1 -= sz1 * (step(0.0, gy1) - 0.5);
    
        vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
        vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
        vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
        vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
        vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
        vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
        vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
        vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);
    
        vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
        g000 *= norm0.x;
        g010 *= norm0.y;
        g100 *= norm0.z;
        g110 *= norm0.w;
        vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
        g001 *= norm1.x;
        g011 *= norm1.y;
        g101 *= norm1.z;
        g111 *= norm1.w;
    
        float n000 = dot(g000, Pf0);
        float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
        float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
        float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
        float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
        float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
        float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
        float n111 = dot(g111, Pf1);
    
        vec3 fade_xyz = fade(Pf0);
        vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
        vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
        float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
        return 2.2 * n_xyz;
    }
    
    float pnoise(vec3 P, vec3 rep)
    {
        vec3 Pi0 = mod(floor(P), rep); // Integer part, modulo period
        vec3 Pi1 = mod(Pi0 + vec3(1.0), rep); // Integer part + 1, mod period
        Pi0 = mod289(Pi0);
        Pi1 = mod289(Pi1);
        vec3 Pf0 = fract(P); // Fractional part for interpolation
        vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
        vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
        vec4 iy = vec4(Pi0.yy, Pi1.yy);
        vec4 iz0 = Pi0.zzzz;
        vec4 iz1 = Pi1.zzzz;
    
        vec4 ixy = permute(permute(ix) + iy);
        vec4 ixy0 = permute(ixy + iz0);
        vec4 ixy1 = permute(ixy + iz1);
    
        vec4 gx0 = ixy0 * (1.0 / 7.0);
        vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
        gx0 = fract(gx0);
        vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
        vec4 sz0 = step(gz0, vec4(0.0));
        gx0 -= sz0 * (step(0.0, gx0) - 0.5);
        gy0 -= sz0 * (step(0.0, gy0) - 0.5);
    
        vec4 gx1 = ixy1 * (1.0 / 7.0);
        vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
        gx1 = fract(gx1);
        vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
        vec4 sz1 = step(gz1, vec4(0.0));
        gx1 -= sz1 * (step(0.0, gx1) - 0.5);
        gy1 -= sz1 * (step(0.0, gy1) - 0.5);
    
        vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
        vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
        vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
        vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
        vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
        vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
        vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
        vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);
    
        vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
        g000 *= norm0.x;
        g010 *= norm0.y;
        g100 *= norm0.z;
        g110 *= norm0.w;
        vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
        g001 *= norm1.x;
        g011 *= norm1.y;
        g101 *= norm1.z;
        g111 *= norm1.w;
    
        float n000 = dot(g000, Pf0);
        float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
        float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
        float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
        float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
        float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
        float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
        float n111 = dot(g111, Pf1);
    
        vec3 fade_xyz = fade(Pf0);
        vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
        vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
        float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
        return 1.5 * n_xyz;
    }
    
    // Turbulence By Jaume Sanchez => https://codepen.io/spite/
    
    varying vec2 vUv;
    varying float noise;
    varying float qnoise;
    varying float displacement;
    
    uniform float time;
    uniform float pointscale;
    uniform float decay;
    uniform float complex;
    uniform float waves;
    uniform float eqcolor;
    uniform bool fragment;
    
    float turbulence( vec3 p) {
        float t = - 0.1;
        for (float f = 1.0 ; f <= 3.0 ; f++ ){
        float power = pow( 2.0, f );
        t += abs( pnoise( vec3( power * p ), vec3( 10.0, 10.0, 10.0 ) ) / power );
        }
        return t;
    }
    
    void main() {
    
        vUv = uv;
    
        noise = (1.0 *  - waves) * turbulence( decay * abs(normal + time));
        qnoise = (2.0 *  - eqcolor) * turbulence( decay * abs(normal + time));
        float b = pnoise( complex * (position) + vec3( 1.0 * time ), vec3( 100.0 ) );
    
        if (fragment == true) {
        displacement = - sin(noise) + normalize(b * 0.5);
        } else {
        displacement = - sin(noise) + cos(b * 0.5);
        }
    
        vec3 newPosition = (position) + (normal * displacement);
        gl_Position = (projectionMatrix * modelViewMatrix) * vec4( newPosition, 1.0 );
        gl_PointSize = (pointscale);
    }`;

const fragmentShader = `
    varying float qnoise;
    uniform float r;
    uniform float g;
    uniform float b;
    uniform float time;
    uniform bool redhell;
    void main() {
        gl_FragColor = vec4(r+abs(qnoise), g+abs(qnoise), b+abs(qnoise), 0.5);
    }`;

export default function AudioVisualiserBall({ ...props }) {
  const pointsRef = useRef();
  const matRef = useRef();
  const geometry = new THREE.IcosahedronBufferGeometry(3, 60);

  const { searchedCrane } = useSnapshot(state);

  const analyzerRef = useRef();
  const micStream = useRef();

  const { gl, scene, camera, size } = useThree();

  const options = {
    perlin: {
      vel: 0.002,
      speed: 0.00005,
      perlins: 1.5,
      decay: 0.24,
      complex: 0.25,
      waves: 9.0,
      eqcolor: 1,
      fragment: true,
      redhell: true,
      r: 1.3,
      h: 0.3,
      b: 0.3,
    },
    spin: {
      sinVel: 0.0,
      ampVel: 80.0,
    },
    time: Date.now(),
  };

  const uniforms = useMemo(() => {
    return {
      time: {
        type: "f",
        value: 0.00005,
      },
      pointscale: {
        type: "f",
        value: 2.5,
      },
      decay: {
        type: "f",
        value: 0.08,
      },
      complex: {
        type: "f",
        value: 0.19,
      },
      waves: {
        type: "f",
        value: 9,
      },
      eqcolor: {
        type: "f",
        value: 0.5,
      },
      fragment: {
        type: "i",
        value: true,
      },
      redhell: {
        type: "i",
        value: true,
      },
      r: {
        type: "i",
        value: 0.9,
      },
      g: {
        type: "i",
        value: 0.1,
      },
      b: {
        type: "i",
        value: 0.02,
      },
    };
  }, []);

  /**
   * Did mount
   */
  useEffect(() => {
    state.startRecordWithAudioVisualiser = enableMic;
    state.stopRecordWithAudioVisualiser = disableMic;

    analyzerRef.current = new AudioMotionAnalyzer(null, {
      source: null,
      mode: 2,
      useCanvas: false, // don't use the canvas
      onCanvasDraw: updateFreqData,
    });
    analyzerRef.current.volume = 0;
  }, []);

  /**
   * ThreeJS did mount
   */
  useEffect(() => {
    state.generateCraneTexture = generateTexture;
  }, [gl, scene, camera, size]);

  /**
   * Visulise audio data to ball
   */
  const updateFreqData = (instance) => {
    const tmp = new Array(instance.getBars().length);
    let barIdx = 0;

    for (const bar of instance.getBars()) {
      tmp[barIdx] = Math.max(...bar.value);
      barIdx++;
    }

    const globle_volume = Math.max(...tmp) / 60;
    const globle_high_tone_value = Math.max(...tmp.slice(60, 82)) / 60;
    const globle_low_tone_value = Math.max(...tmp.slice(1, 50)) / 60;

    

    if (uniforms.pointscale.value<3.5){
      uniforms.pointscale.value += globle_volume;
    }  

    uniforms.decay.value += globle_volume / 12; // Distortion 0-1
    uniforms.complex.value += globle_volume / 32; 
    uniforms.waves.value += globle_high_tone_value / 10;
    // if (uniforms.eqcolor.value<10){
    //   uniforms.eqcolor.value += globle_volume; // Colour 0-15
    // }  
  };

  /**
   * Start analyse from mic
   */
  const enableMic = () => {
    disableMic();
    console.log("start recording");
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream) => {
          // create stream using audioMotion audio context
          micStream.current =
            analyzerRef.current.audioCtx.createMediaStreamSource(stream);
          // connect microphone stream to analyzer
          analyzerRef.current.connectInput(micStream.current);
          // mute output to prevent feedback loops from the speakers
          analyzerRef.current.volume = 0;
        })
        .catch((err) => {
          console.error("Microphone access denied by user", err);
        });
    } else {
      alert("User mediaDevices not available");
    }
  };

  /**
   * Stop analyse from mic
   */
  const disableMic = () => {
    console.log("stop recording", micStream.current, analyzerRef.current);
    if (micStream?.current) {
      // Stop all tracks
      micStream.current.mediaStream
        .getTracks()
        .forEach((track) => track.stop());
      // Disconnect analyzer
      analyzerRef.current.disconnectInput(micStream.current);
      // Clean pointer
      micStream.current = null;
    }
  };

  const generateTexture = async () => {
    console.log("generate texture");
    // switch (searchedCrane.mood) {
    //   case "happy":
    //     scene.background = null;
    //     scene.background = new THREE.Color(0x5702a9);
    //     break;
    //   case "sad":
    //     scene.background = null;
    //     scene.background = new THREE.Color(0x793e23);
    //     break;
    //   case "relax":
    //     scene.background = null;
    //     scene.background = new THREE.Color(0x405946);
    //     break;
    //   case "anxious":
    //     scene.background = null;
    //     scene.background = new THREE.Color(0x38ffc2);
    //     break;
    //   case "light":
    //     scene.background = null;
    //     scene.background = new THREE.Color(0xff6ec1);
    //     break;
    //   case "dark":
    //     scene.background = null;
    //     scene.background = new THREE.Color(0xfff000);
    //     break;
    //   default:
    //     scene.background = null;
    //     break;
    // }
    function getRandomBgColour() {
      const randomBgColor = [
        0x5702a9, //purple
        0x793e23, //darkred
        0x405946, //darkgreen
        0x38ffc2, //lightgreen
        0xff6ec1, //pink
        0xFFCA00, //Yellow
        0x4c743b, //solidergreen
        0xcda884, //earth
        0x8e7cc3, //light purple
        0x040aff, //normal blue
        0x41FF47, //shape green
        0x16F2E8]; //skyblue
      console.log("bg color"+randomBgColor[Math.floor(Math.random() * 12)]);
      return  randomBgColor[Math.floor(Math.random() * 12)];
    }
    scene.background = new THREE.Color(getRandomBgColour());
    gl.render(scene, camera);
    gl.domElement.toBlob(async (blob) => {
      scene.background = null;
      state.generatedTextureBlob = blob;

      // Upload stage
      try {
        const textureFilename = `textures/${uuidv4()}`;
        const storage = getStorage();
        const storageRef = ref(storage, textureFilename);
        const snapshot = await uploadBytes(storageRef, blob);
        console.log("snapshot", snapshot);

        const imageUrl = await getDownloadURL(ref(storage, textureFilename));
        console.log("image url", imageUrl);
        state.generatedTextureUrl = imageUrl;
        state.searchedCrane.textureUrl = imageUrl;
      } catch (e) {
        console.error(e);
      }
    });
  };

  /**
   * Update call
   */
  useFrame((state, delta) => {
    pointsRef.current.rotation.y += options.perlin.vel;
    pointsRef.current.rotation.x =
      Math.sin(options.spin.sinVel) * options.spin.ampVel * Math.PI;

    uniforms.time.value += delta * 0.05;

    switch (searchedCrane.mood) {
      case "happy":
        matRef.current.uniforms.r.value = 0.35;
        matRef.current.uniforms.g.value = 0;
        matRef.current.uniforms.b.value = 0.85;
        break;
      case "sad":
        matRef.current.uniforms.r.value = 0.28;
        matRef.current.uniforms.g.value = 0.08;
        matRef.current.uniforms.b.value = 0;
        break;
      case "relax":
        matRef.current.uniforms.r.value = 0.5;
        matRef.current.uniforms.g.value = 0.5;
        matRef.current.uniforms.b.value = 0;
        break;
      case "anxious":
        matRef.current.uniforms.r.value = 0.07;
        matRef.current.uniforms.g.value = 0.15;
        matRef.current.uniforms.b.value = 0.88;
        break;
      case "light":
        matRef.current.uniforms.r.value = 0;
        matRef.current.uniforms.g.value = 0.36;
        matRef.current.uniforms.b.value = 0.02;
        break;
      case "free":
        matRef.current.uniforms.r.value = 0;
        matRef.current.uniforms.g.value = 7;
        matRef.current.uniforms.b.value = 0.3;
        break;
      default:
        break;
    }
  });

  return (
    <group>
      <points
        ref={pointsRef}
        uniformsNeedUpdate={true}
        geometry={geometry}
        {...props}
      >
        <shaderMaterial
          wireframe={false}
          fog={true}
          ref={matRef}
          attach="material"
          uniformsNeedUpdate={true}
          needsUpdate={true}
          uniforms={uniforms}
          fragmentShader={fragmentShader}
          vertexShader={vertexShader}
        />
      </points>
    </group>
  );
}

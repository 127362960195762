// React core
import { Suspense, useEffect } from "react";

// ThreeJS and Models
import { useFrame, useThree } from "@react-three/fiber";
import {
  OrbitControls,
  PerspectiveCamera,
  Sphere,
  Cone,
} from "@react-three/drei";
import OrigamiCrane from "../../../../components/ThreeDModel/OrigamiCrane";
import { Vector3 } from "three";

// State management
import { useSnapshot } from "valtio";
import state from "../../../../state";

// Fake data generator
import { Api } from "../../../../api";

export default function ExploreStage() {
  const { gl, scene, camera, size } = useThree();

  const { allCranes } = useSnapshot(state);
  const step = 0.01;
  // 

  useFrame(({ camera }) => {
    // pan camera to the init position
    // if (steps[0].cameraPosition.distanceTo(camera.position) >= 0.005) {
    // }
  });

  const initStage = async () => {
    state.allCranes = [];
    state.allCranes = await Api.getCranes();
  };

  useEffect(() => {
    console.log("[explore stage] did mount", gl, scene, camera, size);
    // camera.lookAt(steps[0].lookAtPosition);
    // camera.position.lerp(steps[0].cameraPosition, step);
    // camera.updateProjectionMatrix();
    initStage();
    return () => {
      state.allCranes = [];
    };
  }, []);

  const onClickCraneDetail = (craneData) => {
    // Setup the searched crane
    state.cachedCrane = craneData;
    // Redirect to the detail stage
    state.selectedStage = 4;
  };

  return (
    <>
      {/* Use suspense to async load models from local storage / cloud */}
      <Suspense fallback={null}>
        {allCranes.map((item, index) => (
          <OrigamiCrane
            key={`cranes_${index}`}
            position={item.position}
            scale={item.scale}
            rotation={item.rotation}
            userData={item.userData}
            onClickDetail={onClickCraneDetail}
          />
        ))}
        {/* <Sphere position={[-70.2, -50, 30]} scale={[30,30,30]} ><meshBasicMaterial color="lightblue" wireframe={true}/></Sphere> */}
        <Sphere position={[130.2, -50, -120]} scale={[100, 100, 30]}>
          <meshBasicMaterial color="lightblue" wireframe={true} />
        </Sphere>
        <Sphere position={[-10.2, 20, -50]} scale={[5, 5, 5]}>
          <meshBasicMaterial color="lightblue" wireframe={true} />
        </Sphere>
        <Sphere position={[50.2, -80, 30]} scale={[15, 15, 15]}>
          <meshBasicMaterial color="white" wireframe={true} />
        </Sphere>
        <Cone
          position={[-100, 0, -150]}
          scale={[60, 60, 60]}
          rotation={[0, 180, 180]}
        >
          <meshBasicMaterial color="hotpink" wireframe={true} />
        </Cone>
        <Cone
          position={[-70.2, -50, 30]}
          scale={[30, 30, 30]}
          rotation={[0, 0, 180]}
        >
          <meshBasicMaterial color="lightblue" wireframe={true} />
        </Cone>
        <Sphere position={[-80, 10, 10]} scale={[15, 15, 15]}>
          <meshBasicMaterial color="hotpink" wireframe={true} />
        </Sphere>
      </Suspense>

      {/* THREEjs default orbit control */}
      {/* <MapControls minDistance={15} maxDistance={140} panSpeed={2} /> */}
      <PerspectiveCamera makeDefault position={[200, -10, 30]} fov={[50]} />
      <OrbitControls
        makeDefault
        target={[0, -20, 0]}
        autoRotate={true}
        autoRotateSpeed={0.15}
        maxPolarAngle={2.2}
      />
    </>
  );
}

import {
  collection,
  getDoc,
  getDocs,
  addDoc,
  orderBy,
  query,
  limit,
  where,
} from "firebase/firestore";
import { signInAnonymously, signOut } from "firebase/auth";
import { firestore, auth } from "../firebase";
import * as THREE from "three";
import { Euler } from "three";

export const Api = {
  /**
   * Get total crane count from firebase
   * @return {number} total crane count
   */
  getCraneCount: async () => {
    const cranesRef = collection(firestore, "cranes");
    const snapshot = await getDocs(cranesRef);
    console.log("[getCraneCount] snapshot size:", snapshot.size);
    return snapshot.size;
  },
  /**
   * Create crane
   */
  postCrane: async ({ mood, wish, textureUrl }) => {
    const cranesRef = collection(firestore, "cranes");

    // check sign in
    if (!auth.currentUser) await Api.signIn();

    // find the biggest id crane
    const qLatestCrane = query(
      cranesRef,
      where("id", "!=", NaN),
      orderBy("id", "desc"),
      limit(1)
    );
    const latestCraneRef = await getDocs(qLatestCrane);
    const latestCrane = latestCraneRef.docs[0].data();
    console.log("latest crane: ", latestCrane);

    // add new crane with latest id to the firestore
    const docRef = await addDoc(cranesRef, {
      mood,
      wish,
      textureUrl,
      id: latestCrane.id + 1,
    });
    const doc = await getDoc(docRef);
    return doc.data();
  },
  /**
   * Get crane by given ID
   */
  getCraneById: async (id) => {
    // select collection
    const cranesRef = collection(firestore, "cranes");
    // create query
    const qTargetCrane = query(
      cranesRef,
      where("id", "==", parseInt(id)),
      limit(1)
    );
    // get doc with query from firestore
    const targetCraneRef = await getDocs(qTargetCrane);
    console.log(targetCraneRef);
    // get data from doc ref
    const targetCrane = targetCraneRef.docs[0].data();
    console.log("[getCraneById] resp:", targetCrane);
    return targetCrane;
  },
  /**
   * Get cranes
   */
  getCranes: async (maxCrane = 160) => {
    const cranesRef = collection(firestore, "cranes");
    const qCranes = query(
      cranesRef,
      where("id", "!=", 0),
      orderBy("id", "desc")
    );
    const snapshot = await getDocs(qCranes);

    const result = snapshot.docs.map((item) => {
      const scaleRand = THREE.MathUtils.randFloat(2, 8);
      return {
        userData: {
          ...item.data(),
          animationSpeed: THREE.MathUtils.randFloat(0.1, 0.6),
        },
        position: [
          THREE.MathUtils.randInt(-150, 150),
          THREE.MathUtils.randInt(10, -80),
          THREE.MathUtils.randInt(-150, 150),
        ],
        scale: [scaleRand, scaleRand, scaleRand],
        rotation: new Euler(
          THREE.MathUtils.randFloat(-0.5, 0.5),
          THREE.MathUtils.randFloat(-6.2, -9.8),
          THREE.MathUtils.randFloat(-0.5, 0.5)
        ),
      };
    });
    const shuffledResult = result.sort(() => 0.5 - Math.random());
    console.log(shuffledResult);

    return shuffledResult.slice(0, maxCrane);
  },
  /**
   * Sign in anonymously
   */
  signIn: async () => {
    const resp = await signInAnonymously(auth);
    console.log("sign in: ", resp);
  },
  /**
   * Sign out
   */
  singOut: async () => {
    const resp = await signOut(auth);
    console.log("sign out: ", resp);
  },
};

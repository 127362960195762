import { Container } from "@chakra-ui/react";
import { Canvas } from "@react-three/fiber";
import { Loader } from "@react-three/drei";
import LightingAndEffects from "./commons/LightingAndEffects";
import LandingStage from "./stages/landing/LandingStage";
import ExploreStage from "./stages/explore/ExploreStage";
import { useSnapshot } from "valtio";
import state from "../../state";
import LandingStageUI from "./stages/landing/LandingStageUI";
import ExploreStageUI from "./stages/explore/ExploreStageUI";
import CreateStage from "./stages/create/CreateStage";
import CreateStageUI from "./stages/create/CreateStageUI";
import CraneDetailStage from "./stages/crane-detail/CraneDetailStage";
import CraneDetailStageUI from "./stages/crane-detail/CraneDetailStageUI";
import SearchStage from "./stages/search/SearchStage";
import SearchStageUI from "./stages/search/SearchStageUI";
import SpecialStage from "./stages/special/SpecialStage";
import SpecialStageUI from "./stages/special/SpecialStageUI";


import { ResizeObserver } from "@juggle/resize-observer";

export default function ThreeDWorld() {
  const {
    isExploreStage,
    isLandingStage,
    isCreateStage,
    isDetailStage,
    isSearchStage,
    isSpecialEditionStage,
    shouldBlur,
  } = useSnapshot(state);

  return (
    <Container minWidth={"100%"} minHeight={"100%"} p={0}>
      <Canvas
        resize={{ polyfill: ResizeObserver }}
        style={{
          width: "100%",
          height: "100%",
          display: "block",
          position: "fixed",
          marginBottom: "80px",
          backgroundColor: "black",
          filter: shouldBlur ? "blur(2px)" : "none",
        }}
        flat
        dpr={[1, 2]}
        camera={{
          fov: 60,
          position: [-12, 9, 10.5],
        }}
      >
        {/* Lighting and Post-processing Effects */}
        <LightingAndEffects />

        {/* Main stage */}
        {isLandingStage && <LandingStage />}
        {isCreateStage && <CreateStage />}
        {isExploreStage && <ExploreStage />}
        {isSearchStage && <SearchStage />}
        {isDetailStage && <CraneDetailStage />}
        {isSpecialEditionStage && <SpecialStage />}
      </Canvas>
        
      {/* Overlay UI */}
      {isLandingStage && <LandingStageUI />}
      {isCreateStage && <CreateStageUI />}
      {isExploreStage && <ExploreStageUI />}
      {isDetailStage && <CraneDetailStageUI />}
      {isSearchStage && <SearchStageUI />}  
      {isSpecialEditionStage && <SpecialStageUI />}

      <Loader
        containerStyles={{ backgroundColor: "transparent" }}
        innerStyles={{ backgroundColor: "black" }}
        barStyles={{ backgroundColor: "white" }}
      />
    </Container>
  );
}

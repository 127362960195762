import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { useEffect } from "react";

export default function LightingAndEffects() {
  const { scene } = useThree();

  /**
   * Load skybox
   */
  useEffect(() => {
    const loader = new THREE.CubeTextureLoader();
    // The CubeTextureLoader load method takes an array of urls representing all 6 sides of the cube.
    
    var today = new Date(),
    time = today.getHours();
    console.log(time);
    
    const texture = loader
      .setPath("assets/textures/cubemap/")
      .load(["px.jpg", "nx.jpg", "py.jpg", "ny.jpg", "pz.jpg", "nz.jpg"]);
      
    const texture_night = loader
      .setPath("assets/textures/cubemap/")
      .load(["px-night.jpg", "nx-night.jpg", "py-night.jpg", "ny-night.jpg", "pz-night.jpg", "nz-night.jpg"]);
    // Set the scene background property to the resulting texture.
   
    if (time>18) {
      scene.background = texture_night;
    }else{

     scene.background = texture;
    }
    
  }, []);

  return (
    <>
      {/* Lighting setup */}
      <ambientLight intensity={0.6} color="#ffdfcf" />
      <ambientLight intensity={0.6} color="#ff6694" />
      <pointLight
        position={[2, 5, 6]}
        color="#ff6694"
        intensity={1.2}
        castShadow
      />
      <directionalLight
        position={[0, -5, 0]}
        color="yellow"
        intensity={0.5}
        castShadow
      />
      <pointLight position={[2, 5, 1]} intensity={1.0} castShadow />
    </>
  );
}

import { useFrame, useThree } from "@react-three/fiber";
import { Suspense, useEffect, useRef, useMemo } from "react";
import * as THREE from "three";
import Ground from "../../../../components/ThreeDModel/Ground";
import { StaticOrigamiCrane } from "../../../../components/ThreeDModel/StaticOrigamiCrane";
import Tree from "../../../../components/ThreeDModel/Tree";
import Paper from "../../../../components/ThreeDModel/Paper";
import { Sphere, Cone, GradientTexture, useTexture } from "@react-three/drei";

// State management
import { useSnapshot } from "valtio";
import state from "../../../../state";
import generateLandingCranes from "../../../../utils/generateLandingCranes";

export default function CreateStage() {
  const lookAtTarget = useRef();
  const { createCraneStep, generatedTextureUrl } = useSnapshot(state);
  const { gl, scene, camera, size } = useThree();
  const previewTexture = useMemo(() => {
    const texture = new THREE.TextureLoader().load(generatedTextureUrl);
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.center.set(Math.random(), 0.9);
    texture.repeat.set(4, 4);
    // texture.center.set(-10, -10);
    texture.rotation = 0.5;
    return texture;
  }, [generatedTextureUrl]);

  const steps = [
    {
      lookAtPosition: new THREE.Vector3(-0.3, 1.65, 0.18),
      cameraPosition: new THREE.Vector3(-1, 1.65, 0.18),
    },
    {
      lookAtPosition: new THREE.Vector3(-0.3, 1.65, 0.18),
      cameraPosition: new THREE.Vector3(-0.95, 2, 0.4),
    },
    {
      lookAtPosition: new THREE.Vector3(-0.3, 1.65, 0.18),
      cameraPosition: new THREE.Vector3(-0.95, 3.5, 0.4),
    },
    {
      lookAtPosition: new THREE.Vector3(0.45, 2.25, 0.6),
      cameraPosition: new THREE.Vector3(-3.55, 4.5, 0.8),
    },
    {
      lookAtPosition: new THREE.Vector3(10, 10, -5.6),
      cameraPosition: new THREE.Vector3(-17, 5, 0),
    },
  ];
  const renderRandomCranes = () => {
    const cranes = generateLandingCranes(30);
    return cranes.map((craneData, index) => (
      <StaticOrigamiCrane
        playAnimation
        key={"landing_crane" + index}
        hideHtml
        position={craneData.position}
        scale={craneData.scale}
        rotation={craneData.rotation}
      />
    ));
  };

  useEffect(() => {
    console.log("[create stage] did mount", gl, scene, camera, size);
    lookAtTarget.current.position.set(0.3, 2.15, 0.4);
    camera.lookAt(lookAtTarget.current.position);
  }, [gl, scene, camera, size]);

  useFrame(({ camera }) => {
    // pan camera to the init position
    if (
      camera.position.distanceTo(steps[createCraneStep].cameraPosition) >= 0.006
    ) {
      const step = 0.006;
      camera.position.lerp(steps[createCraneStep].cameraPosition, step);
      lookAtTarget.current.position.lerp(
        steps[createCraneStep].lookAtPosition,
        step
      );
      camera.lookAt(lookAtTarget.current.position);
      camera.updateProjectionMatrix();
    }
  });

  return (
    <>
      <Suspense fallback={null}>
        <Ground position={[-17, 50.3, 2.5]} />
        <group>
          <Tree position={[0, 0, 0]} />
          <mesh ref={lookAtTarget} />
          {renderRandomCranes()}
          <StaticOrigamiCrane
            playAnimation
            key={"landing_crane1"}
            hideHtml
            position={[-6, 7.0, -3.8]}
            scale={[8.5, 6.5, 6.5]}
            rotation={[2.2, 2.2, -2.2]}
            map={previewTexture}
          />
          <pointLight position={[-6, 12.0, -3.8]} intensity={0.4} castShadow />
          <pointLight position={[-1.45, 4.1, 0.7]} intensity={0.4} castShadow />
          {createCraneStep == 3 && (
            <Paper
              position={[-1.45, 3.85, 0.7]}
              scale={0.003}
              rotation={[-1.6, 0.3, 1.3]}
              map={previewTexture}
            />
          )}
        </group>
      </Suspense>

      <gridHelper
        args={[500, 60, "#ffffff", "#ff9999"]}
        position={[0, 13.1, 0]}
      />
      <gridHelper
        args={[500, 5, "#ffffff", "#ffffff"]}
        position={[0, 13.37, 0]}
      />
      <Sphere
        position={[130.2, -70, -80]}
        scale={[100, 100, 30]}
        rotation={[0, -70, 0]}
      >
        <meshBasicMaterial color="lightblue" wireframe={true} />
      </Sphere>
      <Sphere position={[110.2, 20, -40]} scale={[3, 3, 5]}>
        <meshBasicMaterial color="lightblue" wireframe={true} />
      </Sphere>
      <Sphere position={[50.2, -80, 30]} scale={[15, 15, 15]}>
        <meshBasicMaterial color="white" wireframe={true} />
      </Sphere>
      <Cone position={[60, 90, 50]} scale={[40, 40, 40]} rotation={[0, 0, 0]}>
        <meshBasicMaterial color="hotpink" wireframe={true} />
      </Cone>
      <Cone
        position={[70.2, -50, 30]}
        scale={[30, 30, 30]}
        rotation={[0, 0, 180]}
      >
        <meshBasicMaterial color="lightblue" wireframe={true} />
      </Cone>
      <Sphere position={[-80, 10, 10]} scale={[15, 15, 15]}>
        <meshBasicMaterial color="hotpink" wireframe={true} />
      </Sphere>
    </>
  );
}

// React core
import { Suspense, useEffect } from "react";

// threejs
import { Vector3 } from "three";
import { useThree, useFrame } from "@react-three/fiber";
import { Sphere, Cone } from "@react-three/drei";

// Custom 3D models
import Ground from "../../../../components/ThreeDModel/Ground";
import Tree from "../../../../components/ThreeDModel/Tree";

export default function SearchStage() {
  const { gl, scene, camera, size } = useThree();
  const camInfo = {
    lookAtPosition: new Vector3(0, 4.5, 0),
    cameraPosition: new Vector3(-3, 3.5, 0.5),
  };

  useEffect(() => {
    console.log("[search stage] did mount", gl, scene, camera, size);
  }, [gl, scene, camera, size]);

  useFrame(({ camera }) => {
    // pan camera to the init position
    const step = 0.01;
    const { lookAtPosition, cameraPosition } = camInfo;
    if (cameraPosition.distanceTo(camera.position) >= 0.01) {
      camera.lookAt(lookAtPosition);
      camera.position.lerp(cameraPosition, step);
      camera.updateProjectionMatrix();
    }
  });

  return (
    <>
      <Suspense fallback={null}>
        <Ground position={[-8, 30.3, 2.5]} />
        <group>
          <Tree position={[0, 0, 0.8]} />
        </group>
        <gridHelper
          args={[500, 60, "#ffffff", "#ff9999"]}
          position={[0, 13.1, 0]}
        />
        <gridHelper
          args={[500, 5, "#ffffff", "#ffffff"]}
          position={[0, 13.37, 0]}
        />
        <Sphere
          position={[130.2, -70, -80]}
          scale={[100, 100, 30]}
          rotation={[0, -70, 0]}
        >
          <meshBasicMaterial color="lightblue" wireframe={true} />
        </Sphere>
        <Sphere position={[110.2, 20, -40]} scale={[3, 3, 5]}>
          <meshBasicMaterial color="lightblue" wireframe={true} />
        </Sphere>
        <Sphere position={[50.2, -80, 30]} scale={[15, 15, 15]}>
          <meshBasicMaterial color="white" wireframe={true} />
        </Sphere>
        <Cone position={[60, 90, 50]} scale={[40, 40, 40]} rotation={[0, 0, 0]}>
          <meshBasicMaterial color="hotpink" wireframe={true} />
        </Cone>
        <Cone
          position={[70.2, -50, 30]}
          scale={[30, 30, 30]}
          rotation={[0, 0, 180]}
        >
          <meshBasicMaterial color="lightblue" wireframe={true} />
        </Cone>
        <Sphere position={[-80, 10, 10]} scale={[15, 15, 15]}>
          <meshBasicMaterial color="hotpink" wireframe={true} />
        </Sphere>
      </Suspense>
    </>
  );
}

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { DoubleSide } from "three";

export default function Tree({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/assets/models/tree.gltf");
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.branch.geometry} scale={0.48}>
        <meshStandardMaterial
          emissive={0xFFFFFF}
          emissiveIntensity={0.15}
          color={0xFFFFFF}
        />
      </mesh>
      <mesh
        geometry={nodes.leaves.geometry}
        material={nodes.leaves.material}
        position={[-0.52, 3.53, 0.52]}
        rotation={[0.05, 0.38, -1.11]}
        scale={[0.06, 0.06, 0.06]}
      >
        <meshStandardMaterial
          side={DoubleSide}
          emissive={0xFFFFFF}
          color={0xFFFFFF}
          emissiveIntensity={0.55}
          wireframe={true}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("/assets/models/tree.gltf");

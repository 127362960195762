import React, { useRef, useEffect, useMemo } from "react";
import { useGLTF, useTexture, Html, useAnimations } from "@react-three/drei";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import state from "../../state";
import * as THREE from "three";

export function SpecialOrigamiCrane({ ...props }) {
  const group = useRef();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { generatedTextureUrl } = useSnapshot(state);

  const { nodes, materials, animations } = useGLTF(
    "/assets/models/test_crane.gltf"
  );
  const { actions } = useAnimations(animations, group);
  console.log("hi"+i18n.language);
  useEffect(() => {
    if (props.playAnimation) {
      // Play default animation
      const randSpeed = Math.random() * 0 - 0.2;
      actions.KeyAction.timeScale = randSpeed;
      actions.ArmatureAction.timeScale = randSpeed;
      actions.KeyAction.play();
    }
  }, []);

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const renderMainMenu = () => {
    return (
      <Html
        as="div"
        distanceFactor={10}
        position={[0, 0, 0]}
        sprite
        transform
        pointerEvents={null}
        style={{
          userSelect: "none",
          marginRight: "-400px",
          width: "330px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Annotation Line */}
          <div
            style={{
              content: " ",
              display: "block",
              top: "50%",
              width: "120px",
              height: "1px",
              backgroundColor: "white",
            }}
          />

          {/* Detail Panel */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "transparent",
              color: "white",
            }}
          >
            <div
              style={{
                fontSize: "1.8rem",
                textShadow: "1.5px 1.5px rgba(0, 0, 0, 0.1)",
              }}
            >
              {i18n.language === "zh" ? props.info.title_zh : props.info.title_en}
            </div>
            <div
              style={{
                fontSize: "1.2rem",
                textShadow: "0.5px 0.5px rgba(0, 0, 0, 0.1)",
              }}
            >
              {i18n.language === "zh" ? props.info.desc_zh : props.info.desc_en}
            </div>
            <button
              style={{
                pointerEvents: "auto",
                background: "rgb(255 255 255 / 77%)",
                color: "black",
                borderRadius: "50px",
                border: "1px solid white",
                padding: "1.3rem",
                fontSize: "1.3rem",
                marginTop: "0.5rem",
              }}
              type="button"
              onClick={() =>
                openInNewTab(i18n.language === "zh"?props.info.url_zh:props.info.url_en)
              }
            >
              {t("explore.learn_special")}
            </button>
          </div>
        </div>
      </Html>
    );
  };

  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group>
          <group>
            <group rotation={[0.09, Math.PI / 2, 0]}>
              <group position={[0, 0.05, 0]} rotation={[-0.01, -0.04, 2.86]}>
                <group position={[0, 0.36, 0]} rotation={[0.03, -0.07, -2.36]}>
                  <group
                    position={[0, 0.69, 0]}
                    rotation={[-0.02, -0.05, 1.71]}
                  />
                </group>
              </group>
              <group position={[0, 0.05, 0]} rotation={[-0.01, 0.04, -2.86]}>
                <group position={[0, 0.36, 0]} rotation={[0.03, 0.07, 2.38]} />
              </group>
              <group position={[0, 0.05, 0]} rotation={[3.04, 0, 0]}>
                <group position={[0, 0.35, 0]} rotation={[-2.97, 0, 0]}>
                  <group position={[0, 0.24, 0]} rotation={[0.93, 0, 0]}>
                    <group position={[0, 0.17, 0]} rotation={[-0.13, 0, 0]}>
                      <group position={[0, 0.18, 0]} rotation={[0.01, 0, 0]}>
                        <group position={[0, 0.2, 0]} rotation={[0.17, 0, 0]} />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0, 0.05, 0]} rotation={[-3.04, 0, 0]}>
                <group position={[0, 0.35, 0]} rotation={[2.97, 0, 0]}>
                  <group position={[0, 0.24, 0]} rotation={[-0.93, 0, 0]}>
                    <group position={[0, 0.17, 0]} rotation={[0.13, 0, 0]}>
                      <group position={[0, 0.18, 0]} rotation={[-0.01, 0, 0]}>
                        <group
                          name="Bone017"
                          position={[0, 0.2, 0]}
                          rotation={[-0.17, 0, 0]}
                        />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
          <mesh
            name="crane_mesh001"
            geometry={nodes.crane_mesh001.geometry}
            // material={materials["Material.002"]}
            morphTargetDictionary={nodes.crane_mesh001.morphTargetDictionary}
            morphTargetInfluences={nodes.crane_mesh001.morphTargetInfluences}
            onClick={props.onClick}
          >
            {props.map ? (
              <meshStandardMaterial
                emissive={0xebebeb}
                emissiveIntensity={0}
                color={0xebebeb}
                map={props.map}
              />
            ) : (
              <meshStandardMaterial
                emissive={0xebebeb}
                emissiveIntensity={0}
                color={0xebebeb}
              />
            )}

            {renderMainMenu()}
          </mesh>
        </group>
      </group>
    </group>
  );
}

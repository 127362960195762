// React core
import { Suspense, useEffect } from "react";

// ThreeJS and Models
import { useFrame, useThree } from "@react-three/fiber";
import { Vector3 } from "three";
import Tree from "../../../../components/ThreeDModel/Tree";
import Ground from "../../../../components/ThreeDModel/Ground";
import { StaticOrigamiCrane } from "../../../../components/ThreeDModel/StaticOrigamiCrane";
import { Sphere, Cone } from "@react-three/drei";

// State management
import { useSnapshot } from "valtio";
import state from "../../../../state";

// Locale
import generateLandingCranes from "../../../../utils/generateLandingCranes";

export default function LandingStage({ ...props }) {
  const { gl, scene, camera, size } = useThree();
  const { landingStep } = useSnapshot(state);
  const steps = [
    {
      lookAtPosition: new Vector3(0, 4.5, 0),
      cameraPosition: new Vector3(-3, 3.5, 0.5),
    },
    {
      lookAtPosition: new Vector3(0, 4.5, 0),
      cameraPosition: new Vector3(-2, 3, -1),
    },
    {
      lookAtPosition: new Vector3(2.3, 2.15, 0.4),
      cameraPosition: new Vector3(-1, 1.15, 1.4),
    },
    {
      lookAtPosition: new Vector3(-2.3, -2.15, 0.4),
      cameraPosition: new Vector3(-4, 2.15, 0.4),
    },
    {
      lookAtPosition: new Vector3(0, 4.5, 0),
      cameraPosition: new Vector3(-2, 3, -1),
    },
  ];

  useEffect(() => {
    console.log("[landing stage] did mount", gl, scene, camera, size);
  }, [gl, scene, camera, size]);

  useFrame(({ camera }) => {
    // pan camera to the init position
    const step = 0.01;
    const { lookAtPosition, cameraPosition } = steps[landingStep];
    if (cameraPosition.distanceTo(camera.position) >= 0.01) {
      camera.lookAt(lookAtPosition);
      camera.position.lerp(cameraPosition, step);
      camera.updateProjectionMatrix();
    }
  });

  const renderRandomCranes = () => {
    const cranes = generateLandingCranes(30);
    return cranes.map((craneData, index) => (
      <StaticOrigamiCrane
        playAnimation
        key={"landing_crane" + index}
        hideHtml
        position={craneData.position}
        scale={craneData.scale}
        rotation={craneData.rotation}
      />
    ));
  };

  return (
    <>
      <Suspense fallback={null}>
        <Ground position={[-17, 20.3, 2.5]} />
        <group>
          {renderRandomCranes()}
          <StaticOrigamiCrane
            playAnimation
            key={"landing_crane1"}
            hideHtml
            position={[0.2, 5, 0]}
            scale={[1.8, 1.5, 1.35]}
            rotation={[0, -1.9, -0.2]}
          />
        </group>
        <group>
          <Tree position={[0, 0, 0.8]} />
        </group>
      </Suspense>

      <gridHelper
        args={[500, 60, "#ffffff", "#ff9999"]}
        position={[0, 13.1, 0]}
      />
      <gridHelper
        args={[500, 5, "#ffffff", "#ffffff"]}
        position={[0, 13.37, 0]}
      />
      <Sphere
        position={[130.2, -70, -80]}
        scale={[100, 100, 30]}
        rotation={[0, -70, 0]}
      >
        <meshBasicMaterial color="lightblue" wireframe={true} />
      </Sphere>
      <Sphere position={[110.2, 20, -40]} scale={[3, 3, 5]}>
        <meshBasicMaterial color="lightblue" wireframe={true} />
      </Sphere>
      <Sphere position={[50.2, -80, 30]} scale={[15, 15, 15]}>
        <meshBasicMaterial color="white" wireframe={true} />
      </Sphere>
      <Cone position={[60, 90, 50]} scale={[40, 40, 40]} rotation={[0, 0, 0]}>
        <meshBasicMaterial color="hotpink" wireframe={true} />
      </Cone>
      <Cone
        position={[70.2, -50, 30]}
        scale={[30, 30, 30]}
        rotation={[0, 0, 180]}
      >
        <meshBasicMaterial color="lightblue" wireframe={true} />
      </Cone>
      <Sphere position={[-80, 10, 10]} scale={[15, 15, 15]}>
        <meshBasicMaterial color="hotpink" wireframe={true} />
      </Sphere>
    </>
  );
}

import { useThree, useFrame } from "@react-three/fiber";
import { Suspense, useEffect, useRef, useMemo } from "react";
import { StaticOrigamiCrane } from "../../../../components/ThreeDModel/StaticOrigamiCrane";
import Ground from "../../../../components/ThreeDModel/Ground";
import { useSnapshot } from "valtio";
import state from "../../../../state";
import generateLandingCranes from "../../../../utils/generateLandingCranes";
import * as THREE from "three";

export default function CraneDetailStage() {
  const lookAtTarget = useRef();
  const { gl, scene, camera, size } = useThree();
  const { detailCameraTransform, cachedCrane } = useSnapshot(state);

  const craneMap = useMemo(
    () => new THREE.TextureLoader().load(cachedCrane.textureUrl),
    [cachedCrane.textureUrl]
  );

  const renderRandomCranes = () => {
    const cranes = generateLandingCranes(30);
    return cranes.map((craneData, index) => (
      <StaticOrigamiCrane
        playAnimation
        key={"landing_crane" + index}
        hideHtml
        position={craneData.position}
        scale={craneData.scale}
        rotation={craneData.rotation}
      />
    ));
  };

  useEffect(() => {
    console.log("[detail stage] did mount", gl, scene, camera, size);
    camera.lookAt(lookAtTarget.current.position);
    state.screencapDetailCrane = screencapMe;
  }, [gl, scene, camera, size]);

  const screencapMe = () => {
    gl.render(scene, camera);
    state.screencapImageString = gl.domElement.toDataURL();
  };

  useFrame(({ camera }) => {
    // camera position lerp
    camera.position.lerp(detailCameraTransform.cameraPosition, 0.02);

    // look at target position lerp
    lookAtTarget.current.position.lerp(
      detailCameraTransform.lookAtPosition,
      0.1
    );

    // look at target rotation lerp
    const q1 = lookAtTarget.current.quaternion.clone();
    q1.setFromEuler(detailCameraTransform.rotation);
    lookAtTarget.current.quaternion.slerp(q1, 0.1);

    camera.lookAt(lookAtTarget.current.position);
  });

  return (
    <>
      <Suspense fallback={null}>
        <Ground position={[-8, 30.3, 2.5]} />

        <mesh
          ref={lookAtTarget}
          position={[-0.3, 1.8, -0.18]}
          rotation={[-6, 0.1, 0]}
        >
          {renderRandomCranes()}
          {cachedCrane != undefined && (
            <StaticOrigamiCrane
              hideHtml
              position={[0, 0, 0]}
              scale={[0.5, 0.5, 0.5]}
              map={craneMap}
            />
          )}
        </mesh>
      </Suspense>
    </>
  );
}

// React core
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

// Custom components
import { OverlayUIContainerWithBackdrop } from "../../../../components/OverlayUIContainer";
import WaveIcon from "../../../../components/Icons/WaveIcon";
import BlowIcon from "../../../../components/Icons/BlowIcon";
import SettingsBar from "../../commons/SettingsBar";
import RadioCard from "../../../../components/RadioCard";

// Charka UI
import {
  Button,
  Center,
  VStack,
  Flex,
  HStack,
  Text,
  useRadioGroup,
  Grid,
  Container,
  Box,
  Image,
} from "@chakra-ui/react";

// State management
import { useSnapshot } from "valtio";
import state from "../../../../state";

// Locale
import { useTranslation } from "react-i18next";

// Recorder
import { useReactMediaRecorder } from "react-media-recorder";
import AudioVisualiser from "./components/AudioVisualiser";
import { Api } from "../../../../api";

export default function CreateStageUI() {
  const {
    createStep,
    updateBallColour,
    cachedCrane,
    moodTriggered,
    startRecordWithAudioVisualiser,
    stopRecordWithAudioVisualiser,
    generateCraneTexture,
  } = useSnapshot(state);

  const [syncToServer, setSyncToServer] = useState(false);

  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({
      video: false,
      type: "audio/wav",
    });

  const { t } = useTranslation();

  const MotionButton = motion(Button);

  const moodOptions = ["happy", "sad", "relax", "anxious", "light", "free"];
  const moodRadioGroup = useRadioGroup({
    name: "mood",
    defaultValue: "happy",
    onChange: console.log,
  });
  const moodGroup = moodRadioGroup.getRootProps();

  const wishOptions = [
    "world_safe",
    "health",
    "travel_soon",
    "secret",
    "love",
    "all_well",
    "no_worries",
    "success",
    "energy",
  ];
  const wishRadioGroup = useRadioGroup({
    name: "wish",
    defaultValue: "world_safe",
    onChange: console.log,
  });
  const wishGroup = wishRadioGroup.getRootProps();

  const variants = {
    active: {
      y: -10,
      opacity: 1,
      transition: { duration: 2, delay: 0, type: "spring", ease: "easeOut" },
    },
    inactive: {
      y: 0,
      opacity: 0,
    },
  };

  let animation_status;

  /**
   * On media recorder status changed
   */
  useEffect(() => {
    console.log("media recorder status changed to", status);
  }, [status]);

  /**
   * component did mount
   */
  useEffect(() => {
    // Randomise a id for demo
    state.searchedCrane.id = Math.floor(Math.random() * 1000);
  }, []);

  /**
   * Form value changed events
   */
  useEffect(() => {
    state.searchedCrane.mood = moodRadioGroup.value;
  }, [moodRadioGroup]);
  useEffect(() => {
    state.searchedCrane.wish = wishRadioGroup.value;
  }, [wishRadioGroup]);

  /**
   * Upload crane to the server
   */
  const uploadCrane = async () => {
    if (!syncToServer) {
      state.cachedCrane = await Api.postCrane({
        mood: state.searchedCrane.mood,
        wish: state.searchedCrane.wish,
        textureUrl: state.searchedCrane.textureUrl,
      });
      console.log("cached crane: ", state.cachedCrane);
      setSyncToServer(true);
      state.selectedStage = 4;
    }
  };

  /**
   * Switch menus
   */
  const renderCreateCraneForm = () => {
    animation_status = "inactive";
    switch (createStep) {
      default:
      case 0:
        state.createCraneStep = 0;
        return renderGetStartedCreatingMenu();
      case 1:
        state.createCraneStep = 1;
        return renderSelectMoodMenu();
      case 2:
        state.createCraneStep = 2;
        return renderRecordVoiceForMood();
      case 3:
        state.createCraneStep = 3;
        return renderSelectWishMenu();
      case 4:
        state.createCraneStep = 4;
        return renderBlowCraneMenu();
      case 5:
        return renderDebugCompleteMenu();
    }
  };

  /**
   * Generate menu when createStep equals to zero (0)
   * createStep === 0 menu
   */
  const renderGetStartedCreatingMenu = () => {
    return (
      <AnimatePresence exitBeforeEnter>
        <Center flex="1" alignItems={"center"} pointerEvents="auto" px="30">
          <motion.div
            key="1"
            variants={variants}
            initial={{ opacity: 0 }}
            animate="active"
            exit={{ opacity: 0 }}
          >
            <VStack w="100%" px="4" pt="5">
              <Image
                src="assets/how-to-gif.gif"
                alt="How it works"
                pb="5"
                width="14rem"
                opacity={0.8}
              />
              <Text fontSize="4vh" color="white" textAlign="center" pb="2">
                {t("create_crane.How")}
              </Text>
              <Text fontSize="2.1vh" textAlign="center" color="white" pb="6">
                {t("create_crane.How_detail")}
              </Text>
              <MotionButton
                variant="outline"
                backgroundColor="transparent"
                borderColor="white"
                borderRadius="full"
                p={"6"}
                onClick={() => {
                  state.createStep++;
                }}
                style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                transition={{
                  duration: 0.03,
                  type: "spring",
                  ease: "easeInOut",
                }}
                key="im_ready_button"
              >
                <Text color={"white"} size="xl">
                  {t("action.im_ready")}
                </Text>
              </MotionButton>
            </VStack>
          </motion.div>
        </Center>
      </AnimatePresence>
    );
  };

  /**
   * Generate select mood menu when createStep equals to one (1)
   * createStep === 1 menu
   */
  const renderSelectMoodMenu = () => {
    return (
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key="1"
          variants={variants}
          initial={{ opacity: 0 }}
          animate="active"
          exit={{ opacity: 0 }}
        >
          <Center flex="1" alignItems={"center"} pointerEvents="auto" p="30">
            <VStack w="100%" px="2">
            <Box
              cursor="pointer"
              display={moodTriggered === true ? "inherit" : "none"}
              borderRadius="xl"
              borderColor="white"
              background="linear-gradient(45deg, #d140dda3, transparent)"
              boxShadow="2xl"
              textAlign="center"
              whiteSpace="pre-line"
              color="white"
              _focus={{
                boxShadow: "outline",
              }}
              px={5}
              py={5}
              mb={3}
            >
              <Text
                fontSize="1.3rem"
                color="white"
                fontWeight="400"
                textAlign="center"
              >
                {t("moods_detail." + moodRadioGroup.value)}
              </Text>
            </Box>
              
              <Text
                fontSize="1.8rem"
                color="white"
                pb="2"
                textShadow="0px 0px 30px #000000"
                textAlign="center"
                display={moodTriggered === false ? "inherit" : "none"}
              >
                {t("create_crane.today_mood")}
              </Text>
              <Text
                fontSize="1.2rem"
                color="white"
                pb="5"
                textShadow="0px 0px 30px #000000"
                textAlign="center"
                display={moodTriggered === false ? "inherit" : "none"}
              >
                {t("create_crane.today_mood_detail")}
              </Text>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={4}
                {...moodGroup}
                w="100%"
                onClick={() => (state.moodTriggered = true)}
              >
                {moodOptions.map((item) => {
                  const radio = moodRadioGroup.getRadioProps({ value: item });
                  return (
                    <RadioCard key={item} {...radio} fadeInSlide>
                      {t("moods." + item)}
                    </RadioCard>
                  );
                })}
              </Grid>
              {renderCreateActionButtons()}
            </VStack>
          </Center>
        </motion.div>
      </AnimatePresence>
    );
  };

  /**
   * Generate record audio menu when createStep equals to two (2)
   * createStep === 2 menu
   */
  const renderRecordVoiceForMood = () => {
    return (
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key="2"
          variants={variants}
          initial={{ opacity: 0 }}
          animate="active"
          exit={{ opacity: 0 }}
        >
          <Center flex="1" alignItems={"center"} pointerEvents="auto" p="30">
            <VStack w="100%" px="4">
              <Text
                fontSize="1.3rem"
                color="white"
                textShadow="1px 1px 4px #525252"
                align="center"
                pb="7"
              >
                {t("create_crane.let_crane_speaks_for_you")}
              </Text>
              <Flex
                w="10rem"
                h="10rem"
                direction="column"
                justifyContent="center"
                alignItems="center"
                borderWidth="1px"
                borderRadius="100%"
                borderColor="white"
                p="2px"
                userSelect="none"
                onPointerDown={() => startRecordWithAudioVisualiser()}
                onPointerUp={() => stopRecordWithAudioVisualiser()}
              >
                <Flex
                  width="100%"
                  flex="1"
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  borderWidth="1px"
                  borderRadius="100%"
                  borderColor="white"
                  p="5"
                >
                  <WaveIcon style={{ marginBottom: "1rem" }} />
                  <Text color="white" fontSize="xs" textAlign="center" whiteSpace="pre-line" >
                    {t("action.hold_to_record")}
                  </Text>
                </Flex>
              </Flex>
              {renderCreateActionButtons()}
            </VStack>
          </Center>
        </motion.div>
      </AnimatePresence>
    );
  };

  /**
   * Generate select wish menu when createStep equals to three (3)
   * createStep === 3 menu
   */
  const renderSelectWishMenu = () => {
    return (
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key="3"
          variants={variants}
          initial={{ opacity: 0 }}
          animate="active"
          exit={{ opacity: 0 }}
        >
          <Center flex="1" alignItems={"center"} pointerEvents="auto" pb="5">
            <VStack w="100%" px="4">
              <Text
                fontSize="1.3rem"
                color="white"
                textShadow="0px 0px 30px #000000"
                pb="2"
                align="center"
                p="5"
              >
                {t("create_crane.tell_your_wish")}
              </Text>
              <Container
                height="25vh"
                overflow="auto"
                color="#433333"
                css={{
                  "&::-webkit-scrollbar": {
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: `rgba(255, 255, 255, 1)`,
                    borderRadius: "24px",
                  },
                }}
              >
                <Flex direction="column" gap={4} {...wishGroup} w="100%">
                  {wishOptions.map((item) => {
                    const radio = wishRadioGroup.getRadioProps({ value: item });
                    return (
                      <RadioCard key={item} {...radio}>
                        {t("wishes." + item)}
                      </RadioCard>
                    );
                  })}
                </Flex>
              </Container>
              {renderCreateActionButtons()}
            </VStack>
          </Center>
        </motion.div>
      </AnimatePresence>
    );
  };

  /**
   * Generate record audio menu for blowing crane to sky when createStep equals to four (4)
   * createStep === 4 menu
   */
  const renderBlowCraneMenu = () => {
    return (
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key="4"
          variants={variants}
          initial={{ opacity: 0 }}
          animate="active"
          exit={{ opacity: 0 }}
        >
          <Center flex="1" alignItems={"center"} pointerEvents="auto" p="30">
            <VStack w="100%" px="4">
              {/* {mediaBlobUrl && (
                <audio
                  style={{ pointerEvents: "auto" }}
                  src={mediaBlobUrl}
                  type="audio/blob"
                  controls
                  autoPlay
                />
              )} */}
              <Text fontSize="1.3rem" color="white" pb={"6"} textAlign="center">
                {t("create_crane.give_it_strength")}
              </Text>
              {/* <Text fontSize="1rem" color="white"  textAlign="center" pb="2" m="0">
                {t("create_crane.blow_against_the_crane")}
              </Text> */}
              <MotionButton
                variant="outline"
                backgroundColor="transparent"
                borderColor="white"
                borderRadius="full"
                p={"6"}
                onClick={() => {
                  uploadCrane();
                }}
                style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                transition={{
                  duration: 0.03,
                  type: "spring",
                  ease: "easeInOut",
                }}
                key="fly_button"
              >
                <Text color={"white"} size="xl">
                  {t("create_crane.fly_btn")}
                </Text>
              </MotionButton>
              {renderCreateActionButtons(true)}
            </VStack>
          </Center>
        </motion.div>
      </AnimatePresence>
    );
  };

  /**
   * Render action buttons for creation flow
   */
  const renderCreateActionButtons = (hideNext = false) => {
    return (
      <HStack pt="5">
        <Button
          variant="ghost"
          color="white"
          _hover={{ background: "transparent" }}
          _active={{ background: "transparent" }}
          onClick={() => {
            state.createStep--;
            state.moodTriggered = false;
          }}
        >
          {t("action.back")}
        </Button>
        {!hideNext && (
          <div
            style={{
              content: " ",
              width: "4px",
              height: "4px",
              background: "white",
              borderRadius: "100%",
            }}
          />
        )}
        {!hideNext && (
          <Button
            variant="ghost"
            color="white"
            _hover={{ background: "transparent" }}
            _active={{ background: "transparent" }}
            onClick={async () => {
              state.createStep++;
              state.moodTriggered = false;
              if (state.createStep === 3) {
                generateCraneTexture();
              }
            }}
          >
            {t("action.next")}
          </Button>
        )}
      </HStack>
    );
  };

  const renderDebugCompleteMenu = () => {
    return (
      <Button
        isFullWidth
        colorScheme="teal"
        size="xs"
        onClick={() => (state.selectedStage = 4)}
      >
        See your Crane (detail)
      </Button>
    );
  };

  return (
    <OverlayUIContainerWithBackdrop>
      <Flex
        flex={1}
        direction={"column"}
        justifyContent="space-between"
        alignContent="center"
      >
        <SettingsBar showHomeButton />
        {renderCreateCraneForm()}
      </Flex>
      <div
        style={{
          position: "absolute",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "11vh",
          left: 0,
          right: 0,
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {(createStep == 1 || createStep == 2) && <AudioVisualiser />}
      </div>
    </OverlayUIContainerWithBackdrop>
  );
}

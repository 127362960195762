import React from "react";

const WaveIcon = (props) => {
  return (
    <svg
      {...props}
      width="48"
      height="25"
      viewBox="0 0 48 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.8976 8.74196C34.0795 8.74196 34.2538 14.2292 32.9241 16.7143C32.7009 17.1275 32.0918 17.0747 31.945 16.6282C30.4351 11.9987 31.1244 0.75 27.0295 0.75C22.7428 0.75 23.8022 15.3063 22.1337 21.2381C22.0103 21.6885 21.3876 21.7277 21.2055 21.2929C19.443 17.061 19.6408 7.82937 16.3057 7.82937C12.8551 7.82937 13.1489 15.5628 11.7976 18.6785C11.6194 19.0957 11.03 19.1094 10.8322 18.6981C9.84713 16.6438 9.53183 12.5 5.811 12.5H1.47721C0.938667 12.5 0.5 12.9387 0.5 13.4772C0.5 14.0157 0.938667 14.4583 1.47721 14.4583H5.811C8.8895 14.4583 7.84375 21.2812 11.4001 21.2812C14.6979 21.2812 14.3415 13.8591 15.7535 10.5691C15.9258 10.1617 16.5015 10.1735 16.666 10.5808C18.2425 14.5895 18.2131 24.25 21.838 24.25C25.9055 24.25 24.4563 8.86533 26.6222 3.45054C26.7847 3.03733 27.3742 3.01579 27.5602 3.42704C29.5754 7.83329 28.514 19.3405 32.1506 19.3405C35.3603 19.3405 35.5522 14.0412 36.5177 11.4327C36.6763 11.0175 37.2481 10.9882 37.4459 11.3798C38.4564 13.3401 39.1027 16.4167 42.1459 16.4167H46.5228C47.0613 16.4167 47.5 15.978 47.5 15.4395C47.5 14.899 47.0613 14.4603 46.5228 14.4603H42.1459C39.555 14.4603 39.9585 8.74196 36.8976 8.74196Z"
        fill="white"
      />
    </svg>
  );
};

export default WaveIcon;

import styled from "styled-components";

export const OverlayUIContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  pointer-events: none;
  touch-events: none;

  & > button,
  Button {
    pointer-events: auto;
  }
`;

export const OverlayUIContainerWithBackdrop = styled(OverlayUIContainer)`
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.3) 20%,
    rgba(0, 0, 0, 0) 90%
  );
`;

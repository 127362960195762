import {
  Button,
  Center,
  Flex,
  Heading,
  Text,
  VStack,
  Grid,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import state from "../../../../state";

// Custom components
import { OverlayUIContainer } from "../../../../components/OverlayUIContainer";
import SettingsBar from "../../commons/SettingsBar";

const MotionButton = motion(Button);
const MotionText = motion(Text);

export default function SpecialStageUI() {
  const { t, i18n } = useTranslation();
  return (
    <OverlayUIContainer>
      <SettingsBar showHomeButton />
    </OverlayUIContainer>
  );
}

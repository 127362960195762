import {
  Button,
  Center,
  Flex,
  Heading,
  Text,
  VStack,
  Grid,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import state from "../../../../state";

// Custom components
import { OverlayUIContainer } from "../../../../components/OverlayUIContainer";
import SettingsBar from "../../commons/SettingsBar";

const MotionButton = motion(Button);
const MotionText = motion(Text);

export default function ExploreStageUI() {
  const { t, i18n } = useTranslation();
  return (
    <OverlayUIContainer>
      <SettingsBar showHomeButton />
      <Center flex="1" alignItems={"flex-end"} pb="5">
        <VStack w="80%" px="1">
          <Grid templateColumns="repeat(1, 1fr)" gap={4} w="100%" p="10">
            <AnimatePresence>
              {/* Create crane */}
              {/* <Popover placement='top'>
                <PopoverTrigger>
                  <MotionButton
                    variant="outline"
                    backgroundColor="transparent"
                    borderColor="white"
                    borderRadius="full"
                    p={"6"}
                    onClick={() => {
                      // state.selectedStage = 1;
                      // state.landingStep = 0;
                    }}
                    style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                    transition={{
                      duration: 0.03,
                      type: "spring",
                      ease: "easeInOut",
                    }}
                    key="special_edition_button"
                  >
                    <Text color={"white"} size="xl">
                      {t("explore.special_edition")}
                    </Text>
                  </MotionButton>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>{t("explore.coming_soon")}, {t("explore.coming_soon_detail")}</PopoverHeader>
                </PopoverContent>
              </Popover> */}
              
              <MotionButton
                variant="outline"
                backgroundColor="transparent"
                borderColor="white"
                borderRadius="full"
                p={"6"}
                onClick={() => {
                  state.selectedStage = 3;
                  state.landingStep = 0;
                }}
                style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                transition={{
                  duration: 0.03,
                  type: "spring",
                  ease: "easeInOut",
                }}
                key="search_crane_button"
              >
                <Text color={"white"} size="xl">
                  {t("landing.search_crane")}
                </Text>
              </MotionButton>
            </AnimatePresence>
          </Grid>
        </VStack>
      </Center>
    </OverlayUIContainer>
  );
}

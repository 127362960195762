import { proxyWithComputed } from "valtio/utils";
import { Vector3, Euler } from "three";

const state = proxyWithComputed(
  {
    musicIsPlaying: false,

    /**
     * stage 0 - Landing
     * stage 1 - Create
     * stage 2 - Explore
     * stage 3 - Search
     * stage 4 - Crane Detail
     * stage 5 - Special edition
     */
    selectedStage: 0,

    /**
     * landing step 0 - main
     * landing step 1 - story 1
     */
    landingStep: 0,

    /**
     * creation step 0 - init / starting step
     * creation step 1 - pick mood for pattern
     * creation step 2 - record voice for color
     * creation step 3 - tell your wish
     * creation step 4 - cut robe
     * creation step 5 - blow the crane with recorder
     */
    createStep: 0,

    /**
     * detail step 0 - init / show wishes desc, let user to continue to explore or goto screencap mode
     * detail step 1 - screencap mode, user can adjust zoom distance
     * detail step 2 - screencap result preview, user can retake image
     * detail step 3 - share mode, user can download or share the image
     */
    detailStep: 0,
    detailCameraTransform: {
      cameraPosition: new Vector3(-1.3, 1.7, 0.3),
      lookAtPosition: new Vector3(-0.3, 1.8, 0.18),
      rotation: new Euler(0.5, -1.1, 0.5),
    },

    screencapDetailCrane: undefined,
    screencapImageString: "",
    moodTriggered:false,
    allCranes: [],
    isThreeLoaded: false,
    searchedCrane: {
      id: undefined,
      creator: undefined,
      wish: undefined,
      mood: undefined,
      textureUrl: undefined,
      isReady: undefined,
    },

    /**
     * Texture Generator
     */
    startRecordWithAudioVisualiser: undefined,
    stopRecordWithAudioVisualiser: undefined,
    generateCraneTexture: undefined,
    generatedTextureBlob: undefined,
    generatedTextureUrl: undefined,

    /**
     * Active crane
     */
    cachedCrane: undefined,
  },
  {
    isLandingStage: ({ selectedStage }) => selectedStage === 0,
    isCreateStage: ({ selectedStage }) => selectedStage === 1,
    isExploreStage: ({ selectedStage }) => selectedStage === 2,
    isSearchStage: ({ selectedStage }) => selectedStage === 3,
    isDetailStage: ({ selectedStage }) => selectedStage === 4,
    isSpecialEditionStage: ({ selectedStage }) => selectedStage === 5,
    shouldBlur: ({ selectedStage, detailStep }) =>
      selectedStage === 4 && (detailStep === 2 || detailStep === 3),
    resetAllStages: ({
      selectedStage,
      detailStep,
      createStep,
      landingStep,
    }) => {
      selectedStage = 0;
      detailStep = 0;
      createStep = 0;
      landingStep = 0;
    },
  }
);

export default state;

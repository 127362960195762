import {
  Button,
  Center,
  Flex,
  Heading,
  Text,
  VStack,
  Grid,
  Box,
  Image,
  Container,
  extendTheme,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { OverlayUIContainerWithBackdrop } from "../../../../components/OverlayUIContainer";
import RadioCard from "../../../../components/RadioCard";
import state from "../../../../state";
import SettingsBar from "../../commons/SettingsBar";

const MotionButton = motion(Button);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);

const CustomMotionText = ({ ...props }) => {
  const fadeInAnim = { visible: { opacity: 1 }, hidden: { opacity: 0 } };
  const breakpoints = {
    sm: "180px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
  };
  const fadeInSlideAnim = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };
  return (
    <MotionText
      {...props}
      initial="hidden"
      animate="visible"
      variants={props.fadeInSlide === true ? fadeInSlideAnim : fadeInAnim}
      transition={{
        duration: 3,
        delay: 0.3,
        type: "spring",
        ease: "easeOut",
      }}
    >
      {props.children}
    </MotionText>
  );
};

const variants = {
  active: {
    y: -10,
    opacity: 1,
    transition: { duration: 2, delay: 0, type: "spring", ease: "easeOut" },
  },
  inactive: {
    y: 0,
    opacity: 0,
  },
};

export default function LandingStageUI() {
  const { t, i18n } = useTranslation();
  const { landingStep } = useSnapshot(state);

  const renderMenu = () => {
    switch (landingStep) {
      default:
      case 0: // landing menu
        return renderLandingMenu();
      case 1: // story 1
        return renderBackgroundInfo();
      case 2: // main menu
        return renderMainMenu();
      case 3: // main menu
        return renderAbout();
    }
  };

  const renderLandingMenu = () => {
    return (
      <Center flex="1" alignItems={"flex-end"} pb="5">
        <VStack>
          <AnimatePresence>
            <MotionHeading
              key="motion_heading"
              maxWidth={i18n.language === "zh" ? "100%" : "85%"}
              textAlign="center"
              color="white"
              fontSize={i18n.language === "zh" ? "12vh" : "9vh"}
              fontWeight="thin"
              textShadow="0px 0px 20px #00000045"
              style={{ opacity: 0, lineHeight: 1.1 }}
              whiteSpace="pre-line"
              animate={{ opacity: [0, 1] }}
              transition={{
                duration: 2,
                delay: 1,
                type: "spring",
                ease: "easeOut",
              }}
            >
              {t("title")}

              <CustomMotionText
                textAlign="center"
                color="white"
                fontWeight="thin"
                fontSize="1.2rem"
                textShadow="0px 0px 20px #00000045"
                style={{ opacity: 0 }}
                px="10"
                py={i18n.language === "zh" ? "8" : "4"}
                m="0"
                animate={{ opacity: [0, 1] }}
                transition={{
                  duration: 2,
                  delay: 1.5,
                  type: "spring",
                  ease: "easeOut",
                }}
              >
                {t("sub_title")}
              </CustomMotionText>
            </MotionHeading>
            <Image
              src="/assets/qr-code.png"
              width="150px"
              alt="logos"
              py="4"
              display={{ base: "none", sm: "none", lg: "inherit" }}
            />
            <CustomMotionText
              textAlign="center"
              color="white"
              fontWeight="normal"
              fontSize="1.3rem"
              textShadow="0px 0px 20px #00000045"
              style={{ opacity: 0 }}
              display={{ base: "none", sm: "none", lg: "inherit" }}
              px="10"
              py="4"
              m="0"
            >
              {t("scan_qr")}
            </CustomMotionText>
          </AnimatePresence>
        </VStack>
      </Center>
    );
  };

  const renderBackgroundInfo = () => {
    return (
      <Center flex="1" justifyContent={"center"} pb="10" pointerEvents="auto">
        <VStack paddingX={"12"}>
          <AnimatePresence>
            <CustomMotionText
              width="100%"
              fontWeight="bold"
              textShadow="0px 0px 20px #00000045"
              textAlign="left"
              color="white"
              fontSize="4vh"
              style={{ opacity: 0 }}
              p="0"
              m="0"
              key="background_title"
            >
              {t("landing.background_title")}
            </CustomMotionText>
            <CustomMotionText
              textAlign="left"
              color="white"
              fontSize="2.1vh"
              textShadow="1px 1px 10px #00000045"
              style={{ opacity: 0 }}
              p="0"
              m="0"
              maxHeight={"148"}
              key="background_content"
              whiteSpace="pre-line"
            >
              {t("landing.background_content")}
            </CustomMotionText>
          </AnimatePresence>
        </VStack>
      </Center>
    );
  };

  const renderMainMenu = () => {
    return (
      <Center flex="1" alignItems={"center"} pb="5">
        <VStack w="80%" px="1">
          <CustomMotionText
            key="landing_main_menu"
            onClick={() => (state.landingStep = 0)}
            width="100%"
            textAlign="center"
            fontWeight="normal"
            color="white"
            fontSize="4vh"
            textShadow="0px 0px 20px #000000c2"
            style={{ opacity: 0, lineHeight: 1.1 }}
            p="0"
            m="0"
          >
            {t("landing.main_menu")}
          </CustomMotionText>
          <CustomMotionText
            key="landing_main_menu_hint"
            width="100%"
            textAlign="center"
            color="white"
            fontSize="16px"
            textShadow="0px 0px 20px #000000c2"
            style={{ opacity: 0 }}
            p="2.5"
            m="0"
          >
            {t("landing.main_menu_hint")}
          </CustomMotionText>
          <AnimatePresence>
            <motion.div
              key="motion_create_div"
              variants={variants}
              initial={{ opacity: 0 }}
              animate="active"
              exit={{ opacity: 0 }}
            >
              <Grid templateColumns="repeat(1, 1fr)" gap={4} w="100%" p="3">
                {/* Create crane */}
                <MotionButton
                  variant="outline"
                  backgroundColor="transparent"
                  borderColor="white"
                  borderRadius="full"
                  p={"6"}
                  onClick={() => {
                    state.selectedStage = 1;
                    state.landingStep = 0;
                  }}
                  style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                  transition={{
                    duration: 0.03,
                    type: "spring",
                    ease: "easeInOut",
                  }}
                  key="create_crane_button"
                >
                  <Text color={"white"} size="xl">
                    {t("landing.create_crane")}
                  </Text>
                </MotionButton>
                {/* Jump to exhibition  */}
                <MotionButton
                  variant="outline"
                  backgroundColor="transparent"
                  borderColor="white"
                  borderRadius="full"
                  p={"6"}
                  onClick={() => {
                    state.selectedStage = 2;
                    state.landingStep = 0;
                  }}
                  style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                  transition={{
                    duration: 0.03,
                    type: "spring",
                    ease: "easeInOut",
                  }}
                  key="goto_exhibition_button"
                >
                  <Text color={"white"} size="xl">
                    {t("landing.crane_world")}
                  </Text>
                </MotionButton>
                {/* Search crane */}
                <MotionButton
                  variant="outline"
                  backgroundColor="transparent"
                  borderColor="white"
                  borderRadius="full"
                  p={"6"}
                  onClick={() => {
                    state.selectedStage = 3;
                    state.landingStep = 0;
                  }}
                  style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                  transition={{
                    duration: 0.03,
                    type: "spring",
                    ease: "easeInOut",
                  }}
                  key="search_crane_button"
                >
                  <Text color={"white"} size="xl">
                    {t("landing.search_crane")}
                  </Text>
                </MotionButton>
                <MotionButton
                  variant="outline"
                  backgroundColor="transparent"
                  borderColor="white"
                  borderRadius="full"
                  p={"6"}
                  onClick={() => {
                    state.selectedStage = 5;
                    state.landingStep = 0;
                  }}
                  style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                  transition={{
                    duration: 0.03,
                    type: "spring",
                    ease: "easeInOut",
                  }}
                  key="special_button"
                >
                  <Text color={"white"} size="xl">
                    {t("landing.special_crane")}
                  </Text>
                </MotionButton>
                {/* About the proj */}
                <MotionButton
                  variant="outline"
                  backgroundColor="transparent"
                  borderColor="white"
                  borderRadius="full"
                  p={"6"}
                  onClick={() => {
                    // state.selectedStage = 1;
                    state.landingStep = 3;
                  }}
                  style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                  transition={{
                    duration: 0.03,
                    type: "spring",
                    ease: "easeInOut",
                  }}
                  key="about_button"
                >
                  <Text color={"white"} size="xl">
                    {t("landing.about")}
                  </Text>
                </MotionButton>
              </Grid>
            </motion.div>
          </AnimatePresence>
        </VStack>
      </Center>
    );
  };

  const renderAbout = () => {
    return (
      <Center flex="1" justifyContent={"center"} pb="6" pointerEvents="auto">
        <VStack paddingX={"7"}>
          <AnimatePresence>
            <Container
              key="about_container"
              height="65vh"
              overflow="scroll"
              color="#ffffff"
              backgroundColor={ 
                i18n.language === "en" ? "#0000002b" : "#3fa3a359"
              }
              p="6"
            >
              <Box>
                <Heading size="1xl" mb={4} p={0}>
                  {t("about.about_thousand_crane_title")}
                </Heading>
                <Text fontSize="md">
                  {t("about.about_thousand_crane_desc")}
                </Text>
                <Heading size="1xl" mt={10} mb={4} p={0}>
                  {t("about.about_science_in_art_title")}
                </Heading>
                <Text fontSize="md" mb={4}>
                  {t("about.about_science_in_art_desc")}
                </Text>
                <Text fontSize="md">
                  {t("about.other_exhibits")}
                  <a href="https://www.scienceinart.hk/hkjc/artatharbour">https://www.scienceinart.hk/hkjc/artatharbour</a>
                </Text>
                <Image my="5" src="/assets/logo-en.jpg" alt="logos" display={i18n.language === "en" ? "initial" : "none"}/>
                <Image my="5" src="/assets/logo-zh.jpg" alt="logos" display={i18n.language === "zh" ? "initial" : "none"}/>
                
                <Heading size="1xl" mt={10} mb={4} p={0}>
                  {t("about.credit_title")}
                </Heading>
                <Text mb={2} pb={1} whiteSpace="pre-line">
                  {t("about.credit")}
                </Text>
              </Box>
            </Container>
            <MotionButton
              variant="outline"
              backgroundColor="transparent"
              borderColor="white"
              borderRadius="full"
              p={"6"}
              onClick={() => {
                state.selectedStage = 0;
                state.landingStep = 0;
              }}
              style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
              transition={{
                duration: 0.03,
                type: "spring",
                ease: "easeInOut",
              }}
              key="about_back_button"
            >
              <Text color={"white"} size="xl">
                {t("action.back")}
              </Text>
            </MotionButton>
          </AnimatePresence>
        </VStack>
      </Center>
    );
  };

  const renderActionButton = () => {
    return landingStep === 2 || landingStep === 3 ? null : (
      <AnimatePresence>
        <motion.div
          style={{
            opacity: 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "3rem",
            pointerEvents: "auto",
          }}
          animate={{ y: [20, 0], opacity: [0, 1] }}
          transition={{
            duration: 0.8,
            delay: 1,
            type: "spring",
            ease: "easeOut",
          }}
        >
          <AnimatePresence>
            <MotionButton
              display={{ base: "inherit", sm: "inherit", lg: "none" }}
              variant="outline"
              backgroundColor="transparent"
              borderColor="white"
              borderRadius="full"
              p={"6"}
              onClick={() =>
                landingStep === 2
                  ? (state.selectedStage = 1) && (state.landingStep = 0)
                  : state.landingStep++
              }
              style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
              whileHover={{ scale: 1.1 }}
              transition={{
                duration: 0.03,
                type: "spring",
                ease: "easeInOut",
              }}
            >
              <Text color={"white"} size="xl">
                {t(
                  landingStep === 0 ? "action.get_started" : "action.continue"
                )}
              </Text>
            </MotionButton>
          </AnimatePresence>
        </motion.div>
      </AnimatePresence>
    );
  };

  return (
    <OverlayUIContainerWithBackdrop>
      <Flex
        flex={1}
        direction={"column"}
        justifyContent="space-between"
        alignContent="center"
      >
        <SettingsBar />
        <Text 
          color={"white"} 
          pos="absolute" 
          fontSize={i18n.language === "zh" ? "1.2rem" : "1.1rem"}
          p="4" 
          fontWeight="300" 
          whiteSpace="pre-line" 
          display={landingStep === 0 ? "initial" : "none"}>
          {t("sponsor_title")}
        </Text>
        {renderMenu()}
        {renderActionButton()}
      </Flex>
    </OverlayUIContainerWithBackdrop>
  );
}

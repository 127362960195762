import { useEffect, useRef } from "react";

// Custom components
import { OverlayUIContainer } from "../../../../components/OverlayUIContainer";
import SettingsBar from "../../commons/SettingsBar";

// Chakra UI
import {
  Flex,
  Center,
  Button,
  Text,
  VStack,
  HStack,
  Icon,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
} from "@chakra-ui/react";

import { saveAs } from "file-saver";

// Icons
import {
  AiOutlineCamera,
  AiOutlineArrowLeft,
  AiOutlineClose,
  AiOutlineShareAlt,
} from "react-icons/ai";

// Localisation
import { useTranslation } from "react-i18next";

// State
import state from "../../../../state";
import { useSnapshot } from "valtio";
import html2canvas from "html2canvas";

export default function CraneDetailStageUI() {
  const { t } = useTranslation();
  const { detailStep, screencapImageString, cachedCrane } = useSnapshot(state);
  const previewRef = useRef();

  const renderDetailPageMenu = () => {
    switch (detailStep) {
      default:
      case 0:
        return renderYourCraneMenu();
      case 1:
        return renderScreencapMenu();
      case 2:
        return renderScreencapPreviewMenu();
      case 3:
        return renderShareScreencapMenu();
    }
  };

  /**
   * Render search result crane or the crane that just created by user
   * - can switch to screencap mode (detailStep = 1)
   * - can go to explore stage by clicking countinue button (selectedStage will be 2)
   */
  const renderYourCraneMenu = () => {
    return (
      <Center flex="1" alignItems={"flex-end"}>
        <VStack w="400px" textAlign="center" p="10">
          <Text fontSize="1.3rem" color="white" pb="5">
            {t("wishes." + cachedCrane.wish)}
          </Text>
          <Text fontSize="4xl" color="white" pb="7">
            {t("detail_crane.crane_with_unit", { id: cachedCrane.id })}
          </Text>
          <HStack pb="5">
            <Button
              color="white"
              borderWidth="2px"
              borderColor="white"
              variant="outline"
              _hover={{ background: "transparent" }}
              _active={{ background: "transparent" }}
              onClick={() => (state.detailStep = 1)}
              h={12}
            >
              <Icon as={AiOutlineCamera} w={5} h={5} color="white" />
            </Button>
            <Button
              color="gray"
              backgroundColor="white"
              _hover={{ background: "white" }}
              _active={{ background: "white" }}
              px="8"
              h={12}
              onClick={() => (state.selectedStage = 2)}
            >
              {t("detail_crane.other_crane")}
            </Button>
          </HStack>
        </VStack>
      </Center>
    );
  };

  /**
   * Render screencap menu
   */
  const renderScreencapMenu = () => {
    return (
      <Center flex="1" alignItems={"flex-end"}>
        <VStack
          w="100%"
          justifyContent="space-between"
          textAlign="center"
          pb="10"
        >
          <Slider
            width="12rem"
            aria-label="slider-ex-2"
            colorScheme="pink"
            min={-230}
            max={-80}
            defaultValue={-130}
            onChange={(val) => {
              state.detailCameraTransform.rotation.y = val / 100;
            }}
          >
            <SliderTrack bg="white">
              <SliderFilledTrack bg="white" />
            </SliderTrack>
            <SliderThumb
              boxSize={7}
              borderWidth="1px"
              borderColor="white"
              background="transparent"
            >
              <Box backgroundColor="white" borderRadius="full" boxSize={4} />
            </SliderThumb>
          </Slider>
          <Text fontSize="sm" color="white" pb="12" pt="4">
            {t("action.drag_to_change_perspective")}
          </Text>
          <HStack w="100%" px="12" justifyContent="space-between">
            <Button
              variant="outline"
              borderWidth="2px"
              borderColor="white"
              borderRadius="full"
              _hover={{ background: "transparent" }}
              _active={{ background: "transparent" }}
              onClick={() => (state.detailStep = 0)}
              w={12}
              h={12}
            >
              <Icon as={AiOutlineArrowLeft} w={5} h={5} color="white" />
            </Button>
            <Button
              backgroundColor="white"
              borderWidth="2px"
              borderColor="white"
              _hover={{ background: "white" }}
              _active={{ background: "white" }}
              onClick={() =>
                (state.detailStep = 2) && state.screencapDetailCrane()
              }
              w={14}
              h={14}
            >
              <Icon as={AiOutlineCamera} w={6} h={6} color="gray" />
            </Button>
            <Box w={10} h={10} />
          </HStack>
        </VStack>
      </Center>
    );
  };

  /**
   * Render screencap result preview menu
   */
  const renderScreencapPreviewMenu = () => {
    return (
      <Center flex="1" alignItems="center">
        <VStack w="100%" textAlign="center" pb="10">
          {renderScreencapReview()}
          <Button
            color="gray"
            backgroundColor="white"
            _hover={{ background: "white" }}
            _active={{ background: "white" }}
            px="8"
            h={12}
            onClick={() => (state.detailStep = 3)}
          >
            {t("action.confirm")}
          </Button>
          <Text fontSize="sm" color="white" pb="2" pt="2">
            {t("action.retake_photo")}
          </Text>
          <Button
            color="white"
            borderWidth="2px"
            borderColor="white"
            variant="outline"
            borderRadius="full"
            _hover={{ background: "transparent" }}
            _active={{ background: "transparent" }}
            onClick={() => (state.detailStep = 1)}
            w={12}
            h={12}
          >
            <Icon as={AiOutlineClose} w={6} h={6} color="white" />
          </Button>
        </VStack>
      </Center>
    );
  };

  /**
   * Render share screencap share / download menu
   */
  const renderShareScreencapMenu = () => {
    return (
      <Center flex="1" alignItems="center">
        <VStack w="100%" textAlign="center" pb="10">
          {renderScreencapReview()}
          <HStack>
            <Button
              h={12}
              color="gray"
              backgroundColor="white"
              _hover={{ background: "white" }}
              _active={{ background: "white" }}
              px="8"
              onClick={async () => {
                const result = await html2canvas(previewRef.current);
                const image = result.toDataURL("image/png", 1.0);
                saveAs(image, "preview.png");
              }}
            >
              {t("action.download_photo")}
            </Button>
          </HStack>
          <Text fontSize="sm" color="white" pb="2" pt="2">
            {t("action.close")}
          </Text>
          <Button
            color="white"
            borderWidth="2px"
            borderColor="white"
            variant="outline"
            borderRadius="full"
            _hover={{ background: "transparent" }}
            _active={{ background: "transparent" }}
            onClick={() => {
              state.selectedStage = 0;
              state.detailStep = 0;
              state.createStep = 0;
              state.landingStep = 0;
            }}
            w={12}
            h={12}
          >
            <Icon as={AiOutlineClose} w={6} h={6} color="white" />
          </Button>
        </VStack>
      </Center>
    );
  };

  /**
   * Preview screenshot
   */
  const renderScreencapReview = () => {
    return (
      <Box
        ref={previewRef}
        style={{
          content: " ",
          width: "70%",
          height: "45vh",
          background: `url(${screencapImageString})`,
          backgroundSize: "100%, 100%, cover",
          marginBottom: "1rem",
          backgroundPosition: "center",
          borderRadius: "10px",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Flex direction="column" p={5} alignItems="flex-start" height={"100%"}>
          <Text
            color="white"
            fontSize="sm"
            textAlign="left"
            mb="auto"
            whiteSpace="pre-line"
          >
            {t("detail_crane.screencap_remarks")}
          </Text>
          <Text
            color="white"
            fontSize="1.2rem"
            textAlign="left"
            textShadow="1px 1px 8px #000000a8"
          >
            {t("wishes." + cachedCrane.wish)}
          </Text>
          <Text color="grey" fontSize="sm" pt="2">
            {t("detail_crane.screencap_title", {
              id: cachedCrane.id,
              mood: t("moods." + cachedCrane.mood),
            })}
          </Text>
        </Flex>
      </Box>
    );
  };

  return (
    <OverlayUIContainer>
      <Flex
        flex={1}
        direction={"column"}
        justifyContent="space-between"
        alignContent="center"
        pointerEvents="auto"
      >
        <SettingsBar showHomeButton />
        {renderDetailPageMenu()}
      </Flex>
    </OverlayUIContainer>
  );
}
